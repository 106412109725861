<template>
    <v-card flat tile width="100%" class="text-center mt-5" id="patrocinadores">
      <v-container>

        <div id="pontilhada_com_titulo">
          <v-row class="col-12 col-md-12 col-sm-10" id="divisoria_patrocinio">
          
            <div class="pontos-esq_patrocinio"></div>
            <div class="titulo_patrocinio">
              <h2 class="titulo-center">
                Parceiros
              </h2>
            </div>
            <div class="pontos-dir_patrocinio"></div>

          </v-row>
        </div>

        <section id="parceiros"
        >
          <v-row
            class="mx-auto col-12 col-sm-10 col-md-9 col-xl-6"
            justify="space-around"
          >
            <a
              class="logo_patrocinador"
              >
              <!-- href="#" target="_blank" -->
              <v-img alt="abag"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/abag.png"
                height="337"
                width="600"
                style="width:100%;height:auto;max-width:600px;"
              >

              </v-img>
            </a>
            <a
              class="logo_patrocinador"
              >
              <!-- href="#" target="_blank" -->
              <v-img alt="ngrGlobal"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/ngrGlobal.png"
                height="337"
                width="600"
                style="width:100%;height:auto;max-width:600px;"
              >

              </v-img>
            </a>
            <a
              class="logo_patrocinador"
              >
              <!-- href="#" target="_blank" -->
              <v-img alt="spro"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/spro.png"
                height="337"
                width="600"
                style="width:100%;height:auto;max-width:600px;"
              >

              </v-img>
            </a>
            <a
              class="logo_patrocinador"
              >
              <!-- href="#" target="_blank" -->
              <v-img alt="gatec"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/gatec.png"
                height="337"
                width="600"
                style="width:100%;height:auto;max-width:600px;"
              >

              </v-img>
            </a>
            <a
              class="logo_patrocinador"
              >
              <!-- href="#" target="_blank" -->
              <v-img alt="cargill"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/cargill.png"
                height="337"
                width="600"
                style="width:100%;height:auto;max-width:600px;"
              >

              </v-img>
            </a>
            <a
              class="logo_patrocinador"
              >
              <!-- href="#" target="_blank" -->
              <v-img alt="tereos"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/tereos.png"
                height="337"
                width="600"
                style="width:100%;height:auto;max-width:600px;"
              >

              </v-img>
            </a>

          </v-row>
        </section>

        <div id="pontilhada_com_titulo">
          <v-row class="col-12 col-md-12 col-sm-10" id="divisoria_patrocinio">
          
            <div class="pontos-esq_patrocinio"></div>
            <div class="titulo_patrocinio">
              <h2 class="titulo-center">
                Apoiadores
              </h2>
            </div>
            <div class="pontos-dir_patrocinio"></div>

          </v-row>
        </div>

        <section id="apoiadores"
        >
          <v-row
            class="mx-auto col-12 col-sm-10 col-md-9 col-xl-6"
            justify="space-around"
          >
            <!-- <a
              class="logo_patrocinador"
              >
              href="#" target="_blank"
              <v-img alt="amcham"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/amcham.png"
                height="337"
                width="600"
                style="width:100%;height:auto;max-width:600px;"
              ></v-img>
            </a> -->
            <a
              class="logo_patrocinador"
              >
              <!-- href="#" target="_blank" -->
              <v-img alt="camaraInternacionalDeNegcios"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/CamaraInternacionalDeNegocios.png"
                height="337"
                width="600"
                style="width:75%;height:auto;max-width:600px;"
              ></v-img>
            </a>
            <a
              class="logo_patrocinador"
              >
              <!-- href="#" target="_blank" -->
              <v-img alt="camaraDeComercio"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/camaraDeComercio.png"
                height="337"
                width="600"
                style="width:105%;height:auto;max-width:600px;"
              ></v-img>
            </a>
            <a
              class="logo_patrocinador"
              >
              <!-- href="#" target="_blank" -->
              <v-img alt="camaraChinesa"
                src="https://CDN.IGORSVP.COM.BR/e-mails/cwt/parceirosEapoiadores/camaraChinesa.png"
                height="337"
                width="600"
                style="width:105%;height:auto;max-width:600px;"
              ></v-img>
            </a>

          </v-row>
        </section>

      </v-container>
    </v-card>
</template>

<script>
export default {
  name: "Logo",
  data: () => ({
    // items: ["default", "absolute", "fixed"],
    // padless: false,
    // variant: "default",
  }),
};
</script>
<style >
.bulas{
  text-decoration: none;
  font-weight: 600;
  }
  @media screen and (max-width: 900px) {
  
    .bulas{

  font-weight: 500;
  font-size: 0.8rem;
  }

}

/* ======= Divisória com título ======= */
#pontilhada_com_titulo {
  color: var(--cor-secundaria);
  margin-bottom: 3.5rem;
}

#divisoria_patrocinio{
    margin: 1rem auto;
    display: flex;
    flex-flow: nowrap;
    flex-direction: row;
}
.titulo_patrocinio{
  align-items: center;
  display: flex;
  font-size: 22px;
  font-weight: 900;
  justify-content: center;
  margin-bottom: -18px;
  padding: 0;
  width: 20%;
  min-width: 175px;
}

.pontos-esq_patrocinio{
  width: 40%;
  border-bottom-style: dotted;
  border-width: 2px;
  margin-top: -1px;
  margin-left: -5px;
}
.pontos-dir_patrocinio{
  width: 40%;
  border-bottom-style: dotted;
  border-width: 2px;
  margin-top: -1px;
  margin-right: -5px;
}

.titulos_patrocinadores {
  color: #f5821f;
  margin-bottom: -1rem !important;
}

a.logo_patrocinador {
  cursor: default;
}
a.logo_patrocinador:hover {
  cursor: default;
}
.logo_patrocinador {
  margin: 1rem 0 !important;
  cursor: default;
}
</style>
