const versao = 'v1'
const eventoID = 1
const baseApiUrl = 'https://apilandcwt.igorsvp.com.br' //'https://localhost:44326' //'https://mciapi.igorsvp.com.br' //'https://apilandcwt.igorsvp.com.br' //'https://apiinteresse.igorsvp.com.br' //'https://192.168.1.10:44324' //'https://apiland.igorsvp.com.br' //'https://192.168.1.10:44322' //'https://apiland.igorsvp.com.br' //'https://localhost:44326' //'https://localhost:44326' //'https://localhost:5001' //'http://backendastra.teste.com' //'https://localhost:44322' //'https://apiemail.igorsvp.com.br' //'https://localhost:44322' //'https://localhost:5001' //'https://apiland.igorsvp.com.br' //'https://localhost:5001'
const baseEventoUrl = `${baseApiUrl}/api/eventos/${versao}`
const basePaxUrl = `${baseApiUrl}/api/pax/${versao}`
const baseInteresseURl = `${baseApiUrl}/Interesse/${versao}`
const baseInteressePax = `${baseApiUrl}/Interesse/${versao}/at`
const baseCpfUrl = `${baseApiUrl}/api/cpf/${versao}`
const baseLoginUrl = `${baseApiUrl}/api/LoginVerifica/${versao}`
const baseMestre = `${baseApiUrl}/mestre/${versao}`
const baseVerificaEmail = `${baseApiUrl}/api/LoginVerifica/${versao}/inscri`
const baseVerificaCidade = `${baseApiUrl}/evento/${versao}/cidade`
const baseGravaPaxEvento = `${baseApiUrl}/evento/${versao}/novo`
const baseUpdatePaxEvento = `${baseApiUrl}/evento/${versao}/atualizar`
const baseVerificaPresenca = `${baseApiUrl}/evento/${versao}/vpresenca`
const baseVerificaPresencaOptin = `${baseApiUrl}/evento/${versao}/optvpresenca`


// const dataInicioEvento = { DD: 17, MM: 8, YY: 2021, HH: 11, MI: 3, SS: 50 } // '{"DD":"13","MM":"06","YY":"2021","HH":"11","MM":"1","SS":"01"}'// '{"name":"John", "age":30, "city":"New York"}'
// const UrlEvento = 'https://amgen.igorsvp.com.br'


export { baseVerificaPresencaOptin, baseVerificaPresenca, baseUpdatePaxEvento, baseEventoUrl, basePaxUrl, baseCpfUrl, eventoID, baseLoginUrl, baseVerificaEmail, baseMestre, baseInteresseURl, baseInteressePax, baseVerificaCidade, baseGravaPaxEvento }