<template>
  <div class="prog" id="outroseventos">

    <v-container class="mt-0">
      <v-row dense class="col-md-8 col-xl-6 col-sm-10 col-12 mx-auto fonte-tab" justify="center">
<!-- 
        <v-tabs v-model="tab" background-color="transparent" color="var(--cor-fonte-clara)" grow slider-color="var(--cor-fonte-clara)"
          slider-size="2" class="col-md-12 " style="min-width: 69vw;" align-with-title>
          <v-tab v-for="item in programacaoD" :key="item.tab">
            {{ item.tab }}
          </v-tab>
        </v-tabs> -->

        <v-tabs-items class="col-10" v-model="tab">
          <v-tab-item class="col-11 mx-auto"  style="font-size: 1.1rem" v-for="i in programacaoD" :key="i.tab">
           <!-- <div  v-show="(i.tab=='01 DE JULHO')" class="text-center">Em breve programação do dia 01 de julho. <br> Veja a programação do dia 02 de julho</div> -->
           <v-simple-table dark class="tb-prog" >
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-for="(head, index) in i.header" :key="index"  >
                    <td>{{ head.text }}</td>
                  
                    </th>
                  </tr>
                </thead>
                <tbody>

                  <tr v-for="(row, ind) in i.trs" :key="ind" :style="'background-color:' + row.color">

                    <td :style="'color:' + row.fonte" class="col-5 text-center">
                      {{ row.col1 }}
                    </td>
                     <td :style="'color:' + row.fonte" class="col-5 text-center" v-html="row.col3"></td>
                 

                 
                  </tr>


                </tbody>
              </template>
            </v-simple-table>

          

          </v-tab-item>
        </v-tabs-items>
        <!-- <v-card-text>Agenda sujeita a alterações</v-card-text> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Formulario from "./FormularioDialog.vue";

// import axios from "axios";

// import { baseEventoUrl, eventoID } from "@/global";

export default {
  name: "Agenda1",
  props: {
    evento: null,
  },
  components: {
    
  },
  data: () => ({
    tab: 0,
    coluna1: [],
    coluna2: [],
    coluna3: [],
    linhas: 0,


  }),
  computed: {
    ...mapState({
      programacaoD: state =>
        state.outrosEventos.programacao
    })
    ,

  },
  watch: {
    // tab(val) {
    //   this.textoProgramacao();
    //   console.log(val);
    // },
  },
  methods: {
    textoProgramacao() {
      this.coluna1 = [];
      this.horarios.forEach((el) => {
        this.coluna1.push(el.split(";;"));
      });

      this.coluna2 = [];
      this.texto.forEach((el) => {
        this.coluna2.push(el.split(";;"));
      });
      this.coluna3 = [];
      this.textoCol3.forEach((el) => {
        this.coluna3.push(el.split(";;"));
      });
    },
  },
  mounted() {
    let colunas = this.programacaoD
    console.log(colunas, "------colunas")
    let tipo = this.$route.query
    // let evento = tipo.evento || ""
    // // evento = tipo.evento || ""

    //this.programacaoD = this.$store.state.programacao;

    this.$store.state.titulos = "Home";
    this.$store.state.icones = "mdi-home";
    //console.log(this.programacaoD)
  },
  destroyed() {
    this.$store.state.titulos = "";
    this.$store.state.icones = "";
  },
};
</script>
<style>
.prog {
  min-height: 150px;
  margin-top: 2rem;
}

.v-tab {
  font-weight: 500;
}

.tb-prog.theme--dark.v-data-table {
  background-color: transparent;
}

.tb-prog table {
  border-color: var(--cor-fonte-clara);
}
/* #programacao > div > div > div.v-window.col-10.v-item-group.theme--light.v-tabs-items > div > div > div.v-data-table.tb-prog.theme--dark > div > table */
div.v-data-table.tb-prog.theme--dark > div >table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper){
 /* background: var( --cor-fundo)!important; */
 background: transparent !important;
}
/* .tb-prog tr>td:hover{
  color:white!important;
} */

.tb-prog td {
  /* border-bottom: solid 1px var(--cor-fonte-clara) !important ; */
  font-size: 1.1rem !important;
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
  color: var(--cor-fonte-clara);
  font-weight: 400;
  letter-spacing: 0.1rem;
}

.pontos-prog {
  width: 120%;
  border-bottom-style: solid;
  border-width: 2px;
  margin-top: 10px;
  margin-left: 20px;
}

.texto {
  width: 100%;
}

.card-palestrante.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 0 0;
  border-radius: 0;
}

.img-border {
  border-radius: 1rem !important;
}

/* Helper classes */
.basil {
  background-color: rgb(255, 255, 255) !important;
  color: #f70000 !important;
}

.basil--text {
  color: var() !important;
  font-size: 1.1rem !important;
  text-justify: distribute-all-lines;
}

#outroseventos .theme--light.v-tabs>.v-tabs-bar .v-tab:not(.v-tab--active) {
  color: var(--cor-fonte-clara) !important;
}

#outroseventos .v-tab {
  font-size: 1.2rem;
  color: var(--cor-fonte-clara);
}

#outroseventos .theme--light.v-tabs-items {
  background-color: transparent !important;
  color: var();
}
@media screen and (max-width: 800px)  {
  

.fonte-tab table td{
  font-size: 0.8rem!important;
}
}
</style>