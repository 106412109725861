<template>
  <div class="home">
    <Padrao />
  </div>
</template>

<script>
// @ is an alias to /src

import Padrao from "../components/Padrao.vue";

export default {
  name: "Home",
  components: {
    Padrao,
  },

  mounted() {
    this.$store.state.titulos = "Home";
    this.$store.state.icones = "mdi-home";
  },
  created() {
    document.title = this.$route.meta.title;
  },
  destroyed() {
    this.$store.state.titulos = "";
    this.$store.state.icones = "";
  },
};
</script>
<style scoped>
</style>