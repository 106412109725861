<template>
  <div>
    <div v-show="mostraErroAPI" class="erro-api">
      <span v-html="msgErroApi"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    contador: null,
  },
  data: () => ({
    msgErroApi: "",
    mostraErroAPI: false,
  }),
  watch: {
    contador(valor) {
      if (valor == 2) {
        this.mostraErroAPI = true;
        this.msgErroApi = "Por favor aguarde.";
      }
      if (valor == 8) {
        this.mostraErroAPI = true;
        this.msgErroApi = "Estamos com instabilidade.<br>Por favor aguarde.";
      }
      if (valor == 15) {
        this.mostraErroAPI = true;
        this.msgErroApi =
          "Internal server error - Code 500<br>Por favor entre em contato conosco ou tente mais tarde.";
      }
      if (valor < 2) {
        this.msgErroApi = "";
        this.mostraErroAPI = false;
      }

      return valor;
    },
  },
};
</script>

<style>

.erro-api{
  text-align: center;
  margin-top: 1rem;
  font-size: 1.3rem;
}
</style>