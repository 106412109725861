<template>
  <v-app-bar
    app
    :color="this.$store.state.colorMenu"
    height="80"
    id="cabecalho"
    elevation="10"
    class="app-bar-igo"
  >
    <!-- 4 col-md-3 col-sm-3 -->
    <!-- <v-col class="col-md-3 col-xl-6 pt-0 logo font-weight-light titulo-cabecalho-logo"> -->
      <!-- <v-responsive> -->
        <!-- <v-img class="logo" src="/LOGO-DS-ASTRAZENECA.png"></v-img> -->
        <!-- <v-img class="logo" src="/logo-astrazeneca-BRANCO.png"></v-img> -->
      <!-- </v-responsive> -->
    <!-- </v-col> -->
    <!-- 4 col-md-6 col-sm-9 -->
    <v-col
      class="
        menus
        col-md-10

        font-weight-light
        mt-0
        pt-0
        ml-auto
        titulo-cabecalho-tabs
        lg-8
      "
    >
      <v-tabs
        v-model="$store.state.tabCabecalho"
        class="ml-auto"
        style="padding-right: rem !important; padding-left: 0rem !important"
        background-color="transparent"
        grow
        dense
        slider-color="#f5cb5d"
        slider-size="4"
        color="basil-menu"
        center-active
        
      >
        <v-tab
          v-for="(item, i) in menu"
          :key="i"
          v-show="item.mostra"
          @click="rolar(item.id)"
        >
        <span :style="'color:'+item.cor"
        :class="item.class"
        >
       
          {{ item.text }}
        </span>  
        </v-tab>
        <v-btn
        v-if="false"
          class="my-auto pl-3"
          color=""
          outlined
          dark
          @click="fechaForm = true"
          >Tenho interesse</v-btn
        >
        <v-btn
        v-show="cadastroIgo"
          class="my-auto pl-3"
          color=""
          outlined
          dark
          @click="logar"
          >CADASTRE-SE</v-btn
        >
      </v-tabs>
    </v-col>
    <v-col cols="2" md="1"></v-col>
  </v-app-bar>

  <!-- <v-app-bar  id="app-bar"
    absolute
    app
   
    dense
    height="75"
    tilte
   >
   <v-toolbar-title
      class="font-weight-light titulo-cabecalho"
     
    >  <v-img
    width="100"
    height="70"
     src="../assets/logos/valvoline.png"
    ></v-img>
    </v-toolbar-title>
 
  </v-app-bar> -->
</template>

<script>
import { mapState, mapMutations } from "vuex";
// import funcaoLogout from '../views/Exibicao.vue';
// import PageTitle from '../components/PageTitle'
export default {
  components: {
    // PageTitle
  },
  
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    group: null,
    menu: [],
  }),
  computed: {
    ...mapState(["drawer"]),
    ...mapState(["titulos"]),
    ...mapState(["icones"]),
    ...mapState(["interesse"]),
    ...mapState(["cadastroIgo"]),
    // ...mapState(["menu"]),
     fechaForm: {
      get() {
       
        return this.$store.state.fechaForm;
      },
      set(val) {
    
        this.$store.state.fechaForm = val;
      },
    },
  },
  watch: {
    "$store.state.menu": {
      handler(val) {
        this.menu = val;
        console.log(val, "menus");

        return;
      },
      deep: true,
    },
  },
  methods: {
    logar() {
      let site = this.$store.state.siteIgo;
      let q = this.$route.fullPath;
      window.location.href = site + q;
    },
    sair() {
      // funcaoLogout.methods.logOut()
      let menu = [];
      this.$store.state.menu.forEach((item, i) => {
        if (i == 0) {
          item[1] = false;
        } else {
          item[1] = true;
        }
        menu.push(item);
      });

      this.$store.state.menu = menu;
      sessionStorage.removeItem("convidado");
      this.$router.push("/");
    },
    ...mapMutations({
      setDrawer: "SET_DRAWER",
    }),
    rolar(Elem) {
      if (Elem == "sair") this.sair();
      var offsetTrail = document.getElementById(Elem);
      let offsetLeft = 0;
      var offsetTop = -90;
      while (offsetTrail) {
        offsetLeft += offsetTrail.offsetLeft;
        offsetTop += offsetTrail.offsetTop;
        offsetTrail = offsetTrail.offsetParent;
      }
console.log(offsetLeft)
      if (
        navigator.userAgent.indexOf("Mac") != -1 &&
        typeof document.body.leftMargin != "undefined"
      ) {
        offsetLeft += document.body.leftMargin;
        offsetTop += document.body.topMargin;
      }
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    
    },
  },
  mounted() {
    this.$vuetify.theme.dark = false;
    this.menu = this.$store.state.menu;
  },
};
</script>

<style>
  .btn_inscr{
    border: 1px solid #f5cb5d;
    border-radius: 8px;
    padding: 0.5rem;

  }

.app-bar-igo {
  padding: 0 4rem;
  display: flex;
  justify-content: space-between;
  transition: all 700ms !important;
}
.titulo-cabecalho-logo {
  padding-left: 1rem;
  margin-left: 5%;
  margin-right: 0%;
  margin-top: 0%;
}

.titulo-cabecalho-tabs {
  padding-left: 0rem !important;
  padding-right: 1rem !important;
  margin-left: 0%;
  margin-right: 0%;
  margin-top: 0%;
  position: relative;
  left: -10px;
}
.link-decoration {
  text-decoration: none;
  color: none;
}
#cabecalho .v-toolbar__content {
  width: 100%;
}
#cabecalho .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: var(--cor-fonte-clara) !important;
  text-shadow: 2px black;
}
#cabecalho .v-tab {
  font-size: 1rem;
  color: var(--cor-fonte-clara);
}
#cabecalho .theme--light.v-tabs-items {
  background-color: transparent !important;
  color: var(--cor-fonte-clara);
}
.logo {
  width: 18% !important;
  margin-top: 0.1rem;
}
.soLogo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 1100px) {
  .titulo-cabecalho-tabs {
    font-size: 1rem;
  }
  #cabecalho .v-tab {
    font-size: 0.8rem;
    color: var(--cor-fonte-clara);
  }
  .logo {
    width: 40% !important;
  }
}
@media screen and (max-width: 800px) {
  .titulo-cabecalho-tabs {
    font-size: 0.85rem;
  }
  #cabecalho .v-tab {
    font-size: 0.8rem;
    color: var(--cor-fonte-clara);
    left: 0px;
  }
  .logo {
    width: 40% !important;
  }
}
@media screen and (max-width: 500px) {
  .menus {
    display: none;
  }
  .logo {
    padding: 0;
    width: 40% !important;
    margin: auto;
  }
  .soLogo {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>