import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'



Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home,
        meta: { title: 'TR TOUR CUIABA' }
    },


    // {
    //     path: '/exibicao',
    //     name: 'exibicao',
    //     props: true,
    //     component: () =>
    //         import ('../views/Exibicao.vue')
    // },
    // {
    //     path: '/scan',
    //     name: 'scanQR',
    //     props: true,
    //     component: () =>
    //         import ('../components/ScanerQr.vue')
    // },
    // {
    //     path: '/criarEvento',
    //     name: 'Criação de evento',
    //     component: () =>
    //         import ('../views/CriarEvento.vue')
    // },
    // {
    //     path: '/editarevento',
    //     name: 'Edição de evento',
    //     component: () =>
    //         import ('../views/EditarEvento.vue')
    // },
    // {
    //     path: '/editarecriarvoucher',
    //     name: 'Criação e edição de voucher',
    //     component: () =>
    //         import ('../views/Voucher.vue')
    // },
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router