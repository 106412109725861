<template>
  <div class="logarEvento" id="logarEvento">
    <v-row justify="center">
      <v-btn
        v-show="!mostra"
        @click="mostraUsuario"
        rounded
        elevation="2"
        x-large
        color="white"
        class="btn-inscrevase h3-bold"
        >Acesse o evento</v-btn
      >
    </v-row>
    <v-row v-show="mostra" justify="center">
      <v-col cols="11" sm="7" md="3">
        <v-text-field
          :loading="loadingEmail"
          ref="email"
          v-model="emails.rafa"
          label="E-mail"
          placeholder="Email:"
          persistent-hint
          :hint="cadastrado"
          outlined
          dense
          required
        
          dark
        >
        </v-text-field>
      </v-col>
    </v-row>
  <!-- :rules="[rules.required, rules.email]" -->
    <v-row v-show="mostraMsg" justify="center">
      <span class="mb-3"><h5>Este usuário não está cadastrado.</h5> </span>
    </v-row>
    <v-row v-show="mostra" justify="center">
      <v-btn
        depressed
        rounded
        :loading="loadingLogar"
        @click="teste"
        large
        color="white"
        class="btn-inscrevase h3-bold"
      >
        Logar
      </v-btn>
        </v-row>
       <v-row class="mt-6" justify="center">
          <span class="mb-3 d-flex" ><h5>Ainda não se inscreveu</h5> <h5 class=" link" @click="$store.state.fechaForm = true,$store.state.MostraCadastro = true">clique aqui</h5> </span>
       </v-row>
  
  </div>
</template>

<script>
// import { baseLoginUrl, UrlEvento } from "@/global";
// import axios from "axios";
import "../assets/css/logar.css"

export default {
  props: {
    email: String,
  },
  components: {},
  data: function () {
    return {
      emails:{
        rafa:'rafael@igorsvp.com.br'
      },
      loadingLogar:false,
      loadingEmail: false,
      pax: [],
      mostra: false,
      coluna: "grupo-coluna",
      mostraMsg: false,
      emailValido: false,
      erroCampos: "",
      erro: "",
      loader: "none",
      formHasErrors: false,
      cadastrado: "Esse e-mail é o mesmo que você utilizou para se inscrever",
      rules: {
        celular: (value) => {
          if (value != undefined) {
            if (value.length < 11) {
              return "Celular inválido. Ex.: DDD + número ";
            } else return true;
          } else return true;
        },
        crm: (value) => {
          if (value != undefined) {
            if (value.length < 7) {
              return "Digite os 7 números do CRM";
            } else return true;
          } else return true;
        },
        required: (value) => !!value || "Por favor preencha este campo.",
        counter: (value) => value.length <= 20 || "Max 20 caracteres",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

          return pattern.test(value) || "E-mail inválido.";
        },
      },
    };
  },
  computed: {
    form() {
      return {
        //nome: this.pax.nome,
        email: this.pax.email,
        //  celular: this.pax.celular,
      };
    },
  },
  watch: {
    email() {
      
      this.pax.email = this.email;
    },
  },
  methods: {
   
teste(){
 window.location.href = this.$store.state.dadosEvento.link.link;
},
    toast(mensagem, toaster, variant = null, append = true) {
      this.counter++;
      this.$bvToast.toast(mensagem, {
        title: `${this.erro}`,
        toaster: toaster,
        variant: variant,
        solid: true,
        appendToast: append,
        autoHideDelay: "5000",
      });
    },

    mostraUsuario() {
      this.mostra = true;
      let EmailRecebido = this.email;
      if (EmailRecebido !== undefined) {
        this.emailValido = true;
        this.pax.email = this.email;
      } else {
        this.pax.email = "";
      }
    },
    emailInvalido() {
      const value = this.pax.email;
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!pattern.test(value)) {
        this.formHasErrors = true;
        this.form["email"] = false;
        return false;
      }
      this.formHasErrors = false;
      this.form["email"] = value;
      return true;
    },

    async fazerLogin() {
        
      //   this.loading(true, "loadingLogar");
      // let _email = this.pax.email;
      // let evento = JSON.parse(sessionStorage.evento);
      // const url = `${baseLoginUrl}/login`;
      // let idEvento = evento.id_evento;
      // let formData = new FormData();
      // formData.append("email", _email);
      // formData.append("id_evento", idEvento);

      // await axios
      //   .post(url, formData)
      //   .then((res) => {
      //    // console.log(res.data);
      //     this.loading(false, "loadingLogar");
      //     if (res.data == "SEM CADASTRO") {
      //       this.loader = "none";
      //       this.mostraMsg = true;
      //       this.$store.state.MostraCadastro = true;
      //       // console.log(res.data);
      //     }

      //     if (res.data.idPax != 0 && res.data.idPax !== undefined) {
      //       // console.log(res.data,"Logou");
      //       // this.pax = res.data;
      //       // this.$store.state.pax = res.data;
      //       sessionStorage.convidado = JSON.stringify({
      //         id: res.data.idPax,
      //         email: res.data.email,
      //         nome: res.data.nome,
      //       });
      //       if (this.$store.state.dadosEvento.link.exibicaoIGO) {
      //         this.$router.push({ name: "exibicao" });
      //       } else {
      //         window.location.href = this.$store.state.dadosEvento.link.link;
      //       }
      //       //this.$router.push({name:"exibicao",params:{evento:this.$store.state.evento}})
      //       // window.location.href = UrlEvento;
      //     }
      //   })

      //   .catch((error) => {
      //     setTimeout(() => {
      //       this.fazerLogin();
      //     }, 3000);
      //   });
    },
    loading(estado = false, ...loads) {
      const l = loads;
      l.forEach((f) => {
        this[f] = estado;
      });
      this.loader = [];
    },
    submit() {
      this.pax.ip_usuario = sessionStorage.ip;
      this.formHasErrors = false;
      this.emailInvalido();

      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) {
          console.log(f, "passou mas tem erro");
          this.formHasErrors = true;
          this.$refs[f].validate(true);
        } else {
          this.$refs[f].validate(false);
        }
      });

      if (!this.formHasErrors) {
        this.msgPreencha = false;
        this.loading(false, "loadingEmail");
        this.fazerLogin();
      }
    },
  },
  mounted() {
    this.pax.email = 'rafael@igorsvp.com.br' 
    // sessionStorage.removeItem("ip");
    // this.getIp();
  },
};
</script>

<style>

</style>