<template>
  <v-container>
    <section class="relogio col-12 mt-0 pt-0" id="timer">
      <div class="grupo-coluna align-center" v-show="Contador">
            
        <div class="grupo-linha col-8">
          <div class="grupo-coluna-reverse" v-show="D">
            <label for="dias">Days</label>
            <div class="dias r-model" name="dias">10</div>
          </div>
          <div class="grupo-coluna-reverse" v-show="H">
            <label for="horas">Hours</label>

            <div class="horas r-model" name="horas">22</div>
          </div>

          <div class="grupo-coluna-reverse" v-show="M">
            <label for="min">Minutes</label>

            <div class="minutos r-model" name="min">15</div>
          </div>
          <div class="grupo-coluna-reverse">
            <label for="seg">Seconds</label>

            <div class="segundos r-model" name="seg">10</div>
          </div>
        </div>
      </div>
    </section>
  </v-container>
</template>

<script>


 
export default {
   components: {
    
  },
  props: {
    //crono:[],
  },
  data: function () {
    return {
      D: true,
      H: true,
      M: true,
      Contador: true,
    };
  },
  computed: {
    // crono: {
    //   get() {
    //     this.Cronometro();
    //     return this.$store.state.evento;
    //   },
    //   set(val) {
    //     this.Cronometro(val);
    //   },
    // },
  },
  watch: {
    "$store.state.dataEvento": {
      handler() {
        //console.log(val)
        this.Cronometro();
        return;
      },
      deep: true,
    },
    // crono() {
    //   console.log(this)
    //    this.Cronometro();
    //   return;
    // },
  },
  methods: {
    Cronometro() {
      this.$store.state.MostraCadastro = true;
      this.Contador = true;
      let data = this.$store.state.dataEvento; //methods.datasEvento

      if (data.data_inicio != undefined) {
        let dt_inicio = data.data_inicio.split("/");
        let dt_inicio1 = dt_inicio[2].split(" ");
        let dt_inicio2 = dt_inicio1[1].split(":");
        let YY = dt_inicio1[0];
        let MM = dt_inicio[1];
        let DD = dt_inicio[0];
        let HH = dt_inicio2[0];
        let MI = dt_inicio2[1];
        let SS = dt_inicio2[2];
        // var YY = dataInicioEvento.YY;
        // var MM = dataInicioEvento.MM;
        // var DD = dataInicioEvento.DD;
        // var HH = dataInicioEvento.HH;
        // var MI = dataInicioEvento.MI;
        // var SS = dataInicioEvento.SS;

        let futuro = new Date(YY, MM - 1, DD, HH, MI, SS);

        let horaServidor = data.horaServidor;
        // let horaServidor = this.$store.state.evento.data_solicitacao;
        dt_inicio = horaServidor.split("/");
        dt_inicio1 = dt_inicio[2].split(" ");
        dt_inicio2 = dt_inicio1[1].split(":");
        YY = dt_inicio1[0];
        MM = dt_inicio[1];
        DD = dt_inicio[0];
        HH = dt_inicio2[0];
        MI = dt_inicio2[1];
        SS = dt_inicio2[2];

        horaServidor = new Date(YY, MM - 1, DD, HH, MI, SS);
        let segs = parseInt((horaServidor - futuro) / 1000);

        let mins = parseInt(segs / 60);
        let horss = parseInt(mins / 60);
        let diss = parseInt(horss / 24);
        segs = segs - mins * 60;
        mins = mins - horss * 60;
        horss = horss - diss * 24;
        let totalhora = diss + horss + mins + segs;

        if (totalhora > 0) {
          this.Contador = false;
          this.$store.state.MostraCadastro = false;
          this.$store.state.mostraLogin = true;
          return;
        }

        let hoje = new Date();
        let ss = parseInt((futuro - hoje) / 1000);
        let mm = parseInt(ss / 60);
        let hh = parseInt(mm / 60);
        let dd = parseInt(hh / 24);
        ss = ss - mm * 60;
        mm = mm - hh * 60;
        hh = hh - dd * 24;

        let dias = dd && dd > 1 ? dd : dd == 1 ? "1" : (this.D = false);
        let Ldias = dd && dd > 1 ? "Days" : dd == 1 ? "Day" : "";

        let horas = toString(hh).length ? hh : "";
        let Lhoras = toString(hh).length ? "Hours" : "Hour";

        let minutos = toString(mm).length ? mm : "";
        let Lminutos = toString(mm).length ? "Minutes" : "Minute";
        let segundos = ss;

        if (dd + hh + mm + ss > 0) {
          document.querySelector("[name=seg]").innerHTML = segundos;
          document.querySelector("[for=min]").innerHTML = Lminutos;
          document.querySelector("[name=min]").innerHTML = minutos;

          if (dd == 0) {
            this.D = false;
          } else {
            this.D = true;
          }
          if (this.D) {
            document.querySelector("[for=dias]").innerHTML = Ldias;
            document.querySelector("[name=dias]").innerHTML = dias;
          }
          if (hh == 0) {
            this.H = false;
          } else {
            this.H = true;
          }
          if (this.H) {
            document.querySelector("[for=horas]").innerHTML = Lhoras;
            document.querySelector("[name=horas]").innerHTML = horas;
          }

          setTimeout(this.Cronometro, 1000);
        } else {
          this.Contador = false;
          this.$store.state.MostraCadastro = false;
          this.$store.state.mostraLogin = true;
          // setTimeout(atualizaContador, 1000);
        }
      } else {
        setTimeout(this.Cronometro, 1000);
      }
    },
  },
  mounted() {},
};
</script>

<style>

.c-falta {
  font-size: 2rem;
  font-family: Arial, Helvetica, sans-serif;
  margin: auto;
}
.grupo-coluna {
  display: flex;
  flex-direction: column;
}
.grupo-linha {
  display: flex;
  flex-direction: row;
  margin: 0 1rem;
  padding: 0 1rem;

  justify-content: center;
  align-items: center;
    flex-wrap: wrap;
}
.grupo-coluna-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: 0rem 1rem;
}
.grupo-coluna label {
  margin: 1rem 0.5rem auto 0rem !important;
  font-size: 1rem;
  text-align: left;
}

.r-model {
  /* margin: 0.2rem 1rem; */
  /* color: white; */
  font-size: 3rem;
  /* box-shadow: 3px 3px 8px 1px rgb(0 0 0 / 99%); */
  padding: 0.1rem 0.5rem;
  /* width: 10rem; */
  text-align: center;
}
.relogio {
  /* width: 70%; */
  margin: auto;
  /* font-family: "Big Shoulders Stencil Display", cursive; */
  font-family: Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 900px) {

.grupo-coluna label {
  font-size: 0.8rem;
}
.r-model {
  font-size: 1.8rem;
}}

@media screen and (max-width: 500px) {

.grupo-linha {
  flex-wrap: wrap;
}

.grupo-coluna label {
  font-size: 1rem;
}
.r-model {
  font-size: 1.8rem;
}

}
</style>