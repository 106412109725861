import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import store from './config/store'
import router from './router'
import 'animate.css';
import VueYoutube from 'vue-youtube'
import vueVimeoPlayer from 'vue-vimeo-player'
// import CKEditor from 'ckeditor4-vue';
// import Embed from 'v-video-embed'

Vue.use(VueYoutube)
Vue.use(vueVimeoPlayer)
Vue.config.productionTip = false

new Vue({
    vuetify,
    store,
    router,
    render: h => h(App)
}).$mount('#app')