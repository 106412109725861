<template>
  <div class="home" id="padrao" v-scroll="handleScroll">

    <Contador :contador="contadorErro" />

    <Skeleton v-show="!mostraPagina" />

    <div v-show="mostraPagina">
      <v-img contain class="container-img" position="center top" src="/kv-transformationtour-novo.jpg">
        <transition name="fade" enter-active-class="animate__animated animate__zoomIn "
          leave-active-class="animate__animated animate__zoomOut">
          <v-row justify="center" v-show="mostraVideo" v-if="false" class="" style="margin-top: 3rem">
            <v-col cols="12 mb-0" class="d-flex justify-center mt-5">
              <div class="bo" id="player"></div>
              <!-- <client-only>
                <vimeo-player ref="player" @ready="onReady" :options="playerOptions" :video-id="videoID"
                  :player-height="Pheight" />
              </client-only> -->
            </v-col>
            <div class="col-5 d-flex justify-end">
              <v-btn @click="mostraVideo = false" style="text-shadow: 0px 2px 4px white;" dark icon>Close
                <v-icon>mdi-window-close</v-icon>
              </v-btn>
            </div>
          </v-row>
        </transition>
      </v-img>

      <section class="secao-1 col-12 col-md-12 mx-auto" id="sobre" v-show="true">
        <v-virtual-scroll height="50" item-height="20"></v-virtual-scroll>
        <v-col sm="12" md="7" class="mx-auto mb-6">
          <div class="text-center h1-bold" v-html="evento.dataEventoLand"></div>
        </v-col>
        <v-col sm="12" md="7" class="mx-auto">
          <!-- text-center mb-0 h5-bold = class anterior -->
          <div class="descricao-personalizada" v-html="evento.descricao"></div>
          <div class="descricao-personalizada" v-show="mostraFormulario">Inscreva-se gratuitamente clicando no botão abaixo. Vagas limitadas.</div>

        </v-col>
        <Divisoria :corIcone="'var(--secao1-cor-fonte)'" :icon="'schedule'" :titulo="''" />
        <div class="col-12" v-show="false">
          <v-col sm="12" md="7" class="mx-auto mb-6" id="inscricao">
            <div class="text-center h1-bold" v-html="evento.comecou"></div>
          </v-col>
        </div>
        <div :class="classlogar" v-show="false">
          <LogarEvento :email="emailAcessoLogin" />
        </div>
        <Cronometro v-show="true" class="mx-auto mt-0" />
        <div class="col-5" v-show="false">
          <!-- <v-row justify="center" class="linha-inscrevase">
            <v-btn rounded x-large color="var(--botoes-cor-claro)" class="btn-inscrevase h3-bold"
              @click="gravaInteresse">TENHO INTERESSE!</v-btn>
          </v-row> -->
        </div>

      <!-- <div class="col-8 " v-if="btnInteresse">
      
        <div class="text-center mt-10">Participe da nossa avaliação:</div>
          <v-row justify="center" class="linha-inscrevase">
            <v-btn @click="gravaInteresse('nao')" rounded x-large color="var(--botoes-cor-claro)"   class="mr-5 mb-3">NÃO TENHO INTERESSE!</v-btn>
          <v-btn @click="gravaInteresse('tem')"  rounded x-large color="var(--botoes-cor-claro)" >TENHO INTERESSE!</v-btn>
          </v-row>
        </div> -->
        <v-fab-transition v-show="mostraFormulario">
          <v-btn
        style="z-index: 100;"  
        fixed
          v-show="mostraFormulario"
          color="#f5cb5d"
          dark
          top
          right
          outlined
          elevation="8"
          class="mb-15 mr-5 btn_inscr botao-pulse"
          @click="rolar('cadastrese')"
        >
        INSCREVA-SE
        </v-btn>
      </v-fab-transition>
        <div class="col-5" v-show="mostraFormulario">
          <v-row justify="center" class="linha-inscrevase">
            <v-btn rounded x-large  color="var(--cor-fundo)" class="btn-inscrevase h3-bold botao-pulse"    @click="rolar('cadastrese')">
              INSCREVA-SE</v-btn>
          </v-row>
        </div>
        <v-row class="mt-10" v-if="false">

          <v-btn rounded x-large color="var(--secao1-cor-fonte)" class="btn-inscrevase h3-bold" @click="btnConvite">
            Confira um convite especial
          </v-btn>
        </v-row>
        <v-virtual-scroll height="10" item-height="20"></v-virtual-scroll>
      </section>

      <section v-if="true" class="secao-5 col-12 mx-auto" id="informacoes">
        <!-- <v-virtual-scroll height="400" item-height="20"></v-virtual-scroll> -->
        <Divisoria
            :corIcone="'var(--secao3-cor-fonte)'"
            :icon="'mic'"
            :titulo="'SPEAKERS'"
        />
        <PalestranteDinamica v-if="true" class="col-12" /> 
        <div v-if="false">Em Breve!</div>
        <v-virtual-scroll height="100" item-height="20"></v-virtual-scroll> 
      </section>

      <section class="secao-3 col-12 col-md-12 mx-auto" id="patrocinadores">
        <Patrocinio_logos />
      </section>

      <section class="secao-3 col-12 col-md-12 mx-auto" id="programacao">
        <v-virtual-scroll height="50" item-height="20"></v-virtual-scroll>
        <Divisoria :corIcone="'var(--cor-fonte-clara)'" :icon="'event_available'" :titulo="'PROGRAMAÇÃO'" />
        <Agenda1 :evento="evento" />
        <v-virtual-scroll height="20" item-height="20"></v-virtual-scroll>
      </section>


      <section class="secao-4" id="local">
        <v-virtual-scroll height="0" item-height="20"></v-virtual-scroll>
        <LocalEvento class="col-12" />
        <v-virtual-scroll height="10" item-height="20"></v-virtual-scroll>
      </section>

      <section class="secao-1" id="cadastrese" v-show="mostraFormulario">
        <v-virtual-scroll height="50" item-height="20"></v-virtual-scroll>
        <Divisoria
          :corIcone="'var(--secao3-cor-fonte)'"
          :icon="'login'"
          :titulo="'INSCREVA-SE'"
        />

        <v-virtual-scroll height="30" item-height="20"></v-virtual-scroll>
        <Formulario
          :dialogform="fechaForm"
          :evento="evento"
          :loadingEmail="loading4"
          :loading4="loading4"
          :mostraBtnInscreva="mostraBtnInscreva"
          v-if="mostraFormulario"
        />
      </section>
      <v-virtual-scroll height="50" item-height="20"></v-virtual-scroll>

      <section class="secao-5" id="contato">
        <v-virtual-scroll height="10" item-height="20"></v-virtual-scroll>

        <Contato class="col-12" />
        <v-virtual-scroll height="100" item-height="60"></v-virtual-scroll>
      </section>

      <v-virtual-scroll height="50" item-height="20"></v-virtual-scroll>

      <section class="secao-5" id="outrosEventos" v-if="false">
        <v-virtual-scroll height="50" item-height="20"></v-virtual-scroll>
        <Divisoria
          :corIcone="'var(--secao3-cor-fonte)'"
          :icon="'schedule'"
          :titulo="'OUTROS EVENTOS'"
        />
  
        <OutrosEventos class="col-12 col-sm-8" />
        <v-virtual-scroll height="90" item-height="60"></v-virtual-scroll>
      </section>

      <v-fab-transition>
        <v-btn fixed v-show="true" color="#eb670a" dark bottom right fab class="mb-15 mr-5" @click="rolar('padrao')">
          <v-icon>expand_less</v-icon>
        </v-btn>
      </v-fab-transition>

      <!-- <section class="secao-4">
        <Formulario v-if="mostraCadastro"/>
      </section> -->

      <v-dialog v-model="fechaForm" transition="dialog-top-transition" max-width="900" persistent>

        <template v-slot:default="dialog">
          <v-card class="secao-2">
            <v-toolbar class="cabecalho-dialogo">Cadastro</v-toolbar>
            <v-card-text>
              <section class="">
                <Formulario :dialogform="fechaForm" :evento="evento" :loadingEmail="loading4" :loading4="loading4"
                  :mostraBtnInscreva="mostraBtnInscreva" v-if="mostraCadastro" />
              </section>
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn text @click="dialog.value = false">Fechar</v-btn>
            </v-card-actions>
          </v-card>
        </template>

      </v-dialog>
    </div>

    <v-dialog v-model="mostraMSGCadastro" transition="dialog-top-transition" max-width="80vh" persistent>
      <v-card elevation="5" class="mt-10" min-width="90%" style="border-radius: 30px" color="var(--cor-secundaria)">

        <!-- <v-card-title class="mb-5">
          <v-row class="text-h5 fundo-cor" justify="center" style="max-width: 90%; border-radius: 15px;">
            <span>Save The Date</span>
          </v-row>
        </v-card-title>
        <v-card-text v-if="(!btnInteresse && (msgErro == ''))" class="mb-2 text-center pb-0 text-h6 justify-center">
          <span style="font-size: 1.1rem;">

            Você já informou o seu interesse em participar.
          </span>
        </v-card-text>
        <v-card-text class=" text-center px-10 text-h6 justify-center">
          <div>
            {{ msgErro }}
          </div>
          <div style="font-size: 1.5rem;" class="mb-2">Olá {{ nome_pax }},</div>
          Em breve você receberá um convite para se cadastrar.<br>
          Aproveite e visualize as informações do evento. <br>

        </v-card-text>
        <v-card-text v-if="btnInteresse" class="d-flex justify-center pb-0">

          <v-btn @click="gravaInteresse('nao')" dark color="red" class="mr-5">NÃO TENHO INTERESSE!</v-btn>
          <v-btn @click="gravaInteresse('tem')" dark color="success">TENHO INTERESSE!</v-btn>

        </v-card-text>
        <v-card-text v-if="btnInteresse" class=" text-center pb-0 text-h6 justify-center">
          <span style="font-size: 0.9rem;">

            Caso queira participar da nossa avaliação, por favor clique em um dos botões acima.
          </span>
        </v-card-text> -->

        <v-card-title class="mb-5">
          <v-row class="text-h5 fundo-cor" justify="center" style="max-width: 90%; border-radius: 15px">
            <span>Confirme sua presença</span>
          </v-row>
        </v-card-title>
        <v-card-text class="d-flex text-center px-10 text-h6 justify-center">
        
        </v-card-text>
        <v-card-text class="d-flex text-h6 justify-center align-center flex-column">
          <div></div>

        </v-card-text>

        <v-card-text class="d-flex text-h6 justify-center">
          <div class="col-5" v-show="cadastroIgo">
            <v-row justify="center" class="my-2">
              <v-btn rounded x-large dark color="var(--botoes-cor-escuro)" class="h3-bold" style="padding:1rem 5rem !important;" @click="logar">
                CADASTRE-SE</v-btn>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="mostraMSGCadastro = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="mostraObrigado" transition="dialog-top-transition" max-width="80vh" persistent>
      <v-card elevation="5" class="mt-10" min-width="90%" style="border-radius: 30px" color="var(--cor-secundaria)">
        <v-card-title class="mb-5">
          <v-row class="text-h5 fundo-cor" justify="center" style="max-width: 90%; border-radius: 15px;">
            <span>Obrigado</span>
          </v-row>
        </v-card-title>
        <v-card-text class=" text-center px-10 text-h6 justify-center">

          Obrigado por participar da nossa avaliação.

        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="mostraObrigado = false">Close</v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog> -->

    <youtube v-if="false"></youtube>

  </div>
</template>

<script>
import Formulario from "./Formulario.vue";
import Skeleton from "./SkeletonLoad.vue";
import Contador from "./ContadorErro.vue";
import Cronometro from "./Cronometro.vue";
import LogarEvento from "./LogarEvento.vue";
import PalestranteDinamica from "./PaleModeradores.vue";
import Agenda1 from "./AGENDAS/agenda1.vue";
import LocalEvento from "./LocalEvento.vue";
import Contato from "./Contato.vue";
import Divisoria from "./DivisoriaPontilhada.vue";
import OutrosEventos from "./AGENDAS/outroseventos.vue";
import axios from "axios";
import Patrocinio_logos from "./Patrocinio_logos.vue"

//   import cri from "../config/passport.js"
import { baseEventoUrl, baseInteresseURl } from "@/global";
//  import you from "../../public/js/youtube.js"
// let you = require("../../public/js/youtube.js");

export default {
  name: "Padrao",

  components: {
    LocalEvento,
    LogarEvento,
    Formulario,
    Skeleton,
    Contador,
    Cronometro,
    PalestranteDinamica,
    Agenda1,
    Contato,
    Divisoria,
    OutrosEventos,
    Patrocinio_logos,
  },
  data: () => ({
    // videoId: "vlyuKe3h0P4",
    // playerVars: {
    //   autoplay: 1,
    //   height: "360",
    //   width: "140",
    // },
    videoID: "_Cxbu3CgX7g",
    Pheight: 600,
    mostraMSGCadastro: false,
    mostraVideo: true,
    playerOptions: {
      muted: false,
      autoplay: false,
      controls: true,
      title: false,
      portrait: false,
      byline: false,
      transparent: false,
    },
    playerReady: true,
    mostraBtnInscreva: false,
    teste: [],
    emailAcessoLogin: "",
    dialogo: false,
    loading4: false,
    evento: [],
    mostraPagina: false,
    contadorErro: 0,
    mostraContadorErro: false,
    msgErroApi: "",
    dataServidor: null,
    dataAtual: null,
    classlogar: "logar",
    mostraCadastro: true,
    st_v: null,
    stVideo: "rsdfsdl/ksjo;s fd",
    player: null,
    // fechaForm:false,
    estados: null,
    temIGO: false,
    offsetTop: 0,
    mostraObrigado: false,
    btnInteresse: true,
    msgErro: "",
    nome_pax: "",
    windowSize: {
      x: 0,
      y:0,
    },
  }),
  computed: {
    interesse() {
      //console.log(this.$store.state.mostraLogin, "mostra login");
      return this.$store.state.interesse;
    },
    cadastroIgo() {
      console.log(this.$store.state.mostraLogin, "mostra login");
      return this.$store.state.cadastroIgo;
    },
    mostraLogin() {
      //console.log(this.$store.state.mostraLogin, "mostra login");
      return this.$store.state.mostraLogin;
    },
    mostraFormulario(){

     return this.$store.state.mostraFormulario;
    },
          

    fechaForm: {
      get() {
        return this.$store.state.fechaForm;
      },
      set(val) {
        this.$store.state.fechaForm = val;
      },
    },
  },

  watch: {
    async mostraVideo(val) {
      if (!val) {mostraCadastro
        this.pause()
        // this.pause()
      } else {
        await this.timeout(1000);
       
        this.play()
      }
    },
    estados(val) {
      console.log(val, "asdas");
      if (val == 0) {
        this.mostraVideo = false;
      }
      if(val==-1){
        this.player.playVideo()
      }
    },
    mostraCadastro() {
      this.classlogar = "naoInscrito";
    },
    mostraLogin() {
      this.classlogar = "logar";
    },
  },
  methods: {
    handleScroll(){
      this.windowSize = {
        x: window.innerWidth,
        y: window.innerHeight
      };

      if (this.windowSize.x <= 768) {
        if(this.windowSize.y !== 0){
          this.$store.state.colorMenu = "transparent"
        }
      }

      if (this.windowSize.x > 768) {
        if(window.scrollY == 0) {
          this.$store.state.colorMenu = "transparent"
        } else {
          this.$store.state.colorMenu = "rgb(255, 255, 255, 92%)"
        }
      }
    },
    onReady() {
      this.playerReady = true
    },
    play() {
      //this.player.play()
       this.player.playVideo();
       //this.$refs.player.play()
    },
    pause() {
      // this.$refs.player.pause()
      // this.player.pause()
      this.player.pauseVideo()
      //this.player.stopVideo()
    },
    logar() {
      let site = this.$store.state.siteIgo;
      let q = this.$route.fullPath;
      window.location.href = site + q;
    },
    btnConvite() {
      this.rolar("padrao");
      this.mostraVideo = !this.mostraVideo;
    },
    // onYouTubeIframeAPIReady() {
    //   this.player = new YT.Player("player", {
    //     width: 900,

    //     height: 520,
    //     origin: "https://enhertu.igorsvp.com.br",
    //     playerVars: { autoplay: 1, controls: 0, allow: "autoplay" },
    //     videoId: "_Cxbu3CgX7g",
    //     //videoId: "XmY6tgIm9kY",
    //     events: {
    //       onReady: this.onPlayerReady,
    //       //'onPlaybackQualityChange': onPlayerPlaybackQualityChange,
    //       onStateChange: this.onPlayerStateChange,
    //       onStop: this.onPlayerStop,
    //     },
    //   });
    // },
    // onPlayerReady(event) {
    //   event.target.setVolume(100);
    //   this.player.stopVideo();

    //   //this.mostraVideo = true;
    // },
    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
    // onPlayerStop(event) {
    //   console.log(event.target, "player stop");
    // },

    // onPlayerStateChange(event) {
    //   let state = event.target.getPlayerState();
    //   this.estados = state;
    // },

    rolar(Elem) {
      if (Elem == "sair") this.sair();
      if(Elem == "padrao") this.$store.state.tabCabecalho = 2;
      console.log(Elem);
      var offsetTrail = document.getElementById(Elem);
      var offsetLeft = 0;
      console.log(offsetLeft);
      var offsetTop = -90;
      while (offsetTrail) {
        offsetLeft += offsetTrail.offsetLeft;
        offsetTop += offsetTrail.offsetTop;
        offsetTrail = offsetTrail.offsetParent;
      }
      if (
        navigator.userAgent.indexOf("Mac") != -1 &&
        typeof document.body.leftMargin != "undefined"
      ) {
        offsetLeft += document.body.leftMargin;
        offsetTop += document.body.topMargin;
      }
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    },
    verificaUrl() {
      let that = this;
      // console.log(this.$route.query.id);
      //SAVE THE DATE

      this.$store.state.cadastroIgo = false;
      this.$store.state.mostraFormulario = false;

      // if (this.$route.query.id == null || this.$route.query["l!"] == null) {
      //   //this.$store.state.interesse = false;
      //   this.$store.state.cadastroIgo = false;
      //   this.btnInteresse = false
      // } else {

      //   this.$store.state.cadastroIgo = false;
      //   this.verificaInteresse()

      // }

      if (this.$route.query.id == null || this.$route.query["w!"] == null) {
        //this.$store.state.interesse = false;
        this.$store.state.mostraFormulario = false;
        // this.$store.state.cadastroIgo = false;
        if(this.$route.query.optin !== undefined || this.$route.query.redesocial !== undefined){
          this.$store.state.mostraFormulario = true;
          
         // console.log('optin',this.$store.state.mostraFormulario)
       }else{

         this.$store.state.mostraFormulario = false;
       }
      } else {
        
        this.$store.state.mostraFormulario = true;
        //  this.$store.state.cadastroIgo = true;
        setTimeout(() => {
//==============mostra mensagem do cadastro=============================================
          that.mostraMSGCadastro = false;
        }, 1000);
      }
    },


    async puxaEvento() {
      this.load = "content-load";
      this.evento = this.$store.state.dadosEvento;
      let data = new Date();
      console.log(data.toLocaleDateString() + " " + data.toLocaleTimeString());
      this.dataAtual =
        data.toLocaleDateString() + " " + data.toLocaleTimeString();
      this.evento.dataServidor = this.dataAtual;
      this.datasEvento();
      this.mostraPagina = false;

      //this.$store.state.evento = res.data;
      sessionStorage.evento = JSON.stringify({
        id_evento: this.evento.id_evento,
        nome_evento: this.evento.nome_evento,
      });

      this.contadorErro = 0;
      this.mostraContadorErro = false;

      // this.$store.state.dadosEvento = this.evento;
      this.mostraPagina = true;
      this.evento.dataServidor = this.dataAtual;
      this.datasEvento();
    },
    async gravaInteresse(val) {
      let interesse;
      if (val == "tem") {
        interesse = "TENHO INTERESSE"
      }
      if (val == "nao") {
        interesse = "NÃO TENHO INTERESSE"
      }
      let tokem = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJhZmFlbEBpZ29yc3ZwLmNvbS5iciIsInJvbGUiOlsiYWRtaW5pc3RyYWRvciIsIklHT19SU1ZQIl0sImUtbWFpbCI6InJhZmFlbEBpZ29yc3ZwLmNvbS5iciIsIm5vbWVfdXN1YXJpbyI6IlJhZmFlbCBBbnRvbmFjY2kiLCJuaXZlbCI6ImFkbWluaXN0cmFkb3IiLCJjYXRlZ29yaWEiOiJJR09fUlNWUCIsImlkX3VzdWFyaW8iOiI2IiwiaWRfY2xpZW50ZSI6IjIyIiwiY2xpZW50ZSI6IklHTyIsIm5iZiI6MTY1NTk4OTMxNiwiZXhwIjoxNjU3NzE3MzE1LCJpYXQiOjE2NTU5ODkzMTZ9.hIYz4ungOxMhBWTgYwVKzocuHLnIMvXWaUQamoKj8Sk"
      let url = `${baseInteresseURl}/grava`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokem}`,
        },
      };
      let dados = { url: this.$route.fullPath, interresse: interesse }

      await axios
        .post(url, dados, config)
        .then((res) => {
          this.mostraObrigado = true
          this.mostraMSGCadastro = false
          this.btnInteresse = false
          console.log(res.data, "retorno interesse");
          this.msgErro = ""
        })
        .catch((err) => {
          this.msgErro = `Estamos com isntabilidades(500) tente mais tarde!`
          this.btnInteresse = false
          console.log(err)
        });
    },
    async verificaInteresse() {

      let tokem = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJhZmFlbEBpZ29yc3ZwLmNvbS5iciIsInJvbGUiOlsiYWRtaW5pc3RyYWRvciIsIklHT19SU1ZQIl0sImUtbWFpbCI6InJhZmFlbEBpZ29yc3ZwLmNvbS5iciIsIm5vbWVfdXN1YXJpbyI6IlJhZmFlbCBBbnRvbmFjY2kiLCJuaXZlbCI6ImFkbWluaXN0cmFkb3IiLCJjYXRlZ29yaWEiOiJJR09fUlNWUCIsImlkX3VzdWFyaW8iOiI2IiwiaWRfY2xpZW50ZSI6IjIyIiwiY2xpZW50ZSI6IklHTyIsIm5iZiI6MTY1NTk4OTMxNiwiZXhwIjoxNjU3NzE3MzE1LCJpYXQiOjE2NTU5ODkzMTZ9.hIYz4ungOxMhBWTgYwVKzocuHLnIMvXWaUQamoKj8Sk"
      let url = `${baseInteresseURl}/verifica`;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokem}`,
        },
      };
      let dados = { url: this.$route.fullPath }
      let that = this
      await axios
        .post(url, dados, config)
        .then((res) => {
          let r = res.data
          setTimeout(() => {

            that.mostraMSGCadastro = true;
           
          }, 400);
          this.nome_pax = r.nome_original
          if (r.reminder == "TENHO INTERESSE" || r.reminder == "NÃO TENHO INTERESSE") {
            this.btnInteresse = false
          }
          this.msgErro = ""
          console.log(r, "retorno interesse", this.btnInteresse, "botao", this.nome_pax ,"nome do pax");

        })
        .catch((err) => {
          this.msgErro = `Estamos com isntabilidades (500)`
          that.mostraMSGCadastro = true;
          this.btnInteresse = false
          console.log(err)

        });
    },
    datasEvento() {
      this.$store.state.dataEvento = {
        data_inicio: this.evento.data_inicio,
        data_termino: this.evento.data_termino,
        horaServidor: this.evento.dataServidor,
      };
      console.log(this.evento);
    },
    async getIp() {
      // await $.getJSON(
      //   "https://api.ipify.org?format=jsonp&callback=?",
      //   function (json) {
      //     sessionStorage.ip = json.ip;
      //     return json.ip;
      //   }
      // );
    },
  },
  created() {
    // console.log("create");
    // let ineter = setInterval(() => {
    //   console.log("no inter");
    //   if (YT) {
    //     if (YT.loaded == 1) {
    //       this.onYouTubeIframeAPIReady();

    //       clearInterval(ineter);
    //     }
    //   }
    // }, 100);
  },
  async mounted() {
    sessionStorage.removeItem("evento");
    sessionStorage.removeItem("ip");
    this.$store.state.titulos = "Home";
    this.$store.state.icones = "mdi-home";
    this.verificaUrl();
    this.getIp();
    this.puxaEvento();
    // this.verificaInteresse();
  },

  destroyed() {
    this.$store.state.titulos = "";
    this.$store.state.icones = "";
  },
};
</script>
<style>
/* .home{
 
  background-image:  linear-gradient(to right, transparent -61%, #fff 101%), url("/padrao-bolinhas.png");

  background-repeat: repeat;
  opacity: 10;
} 
*/

.descricao-personalizada {
  font-size: 1rem;
  font-weight: 600;
  text-indent: 20px;
  line-height: 2rem;
}

.descricao-personalizada02 {
  font-size: 1rem;
  font-weight: 600;
  text-indent: 20px;
  margin-top: -1.2rem;
}

.btn-inscrevase .v-btn__content {
  color:white!important;
}
</style>
<style>

.v-dialog .v-dialog--active {
  overflow: hidden;
}

.bo {
  border-radius: 2rem;
}

/* .container-img {
  top: 75px !important;
} */

</style>