<template>
  <div>
    <v-skeleton-loader
      v-bind="attrs"
      type="card-avatar,article,article,divider"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  data: () => ({
    dialogo: false,
    loading4: false,
    evento: [],
    attrs: {
      class: "my-5 col-md-8 mx-auto loader-skeleton",
      boilerplate: false,
      elevation: 2,
    },
  }),
  computed: {},
};
</script>

<style>
.loader-skeleton {
  height: 80vh;
}
</style>