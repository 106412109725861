import Vue from 'vue'
import Vuex from 'vuex'



Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        tabCabecalho: 2,
        colorMenu: "transparent",
        cadastroIgo: true,
        interesse: false,
        corSecundaria: "#dc9e09",
        pax: [],
        barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
        isMenuVisivel: false,
        drawer: null,
        MostraCadastro: false,
        mostraBtnInscreva: null,
        mostraLogin: false,
        titulos: null,
        icones: null,
        evento: [],
        selIdEvento: null,
        PanelEvento: false,
        banco: String,
        selIdAgencia: null,
        selIdCliente: null,
        selEtapas: null,
        chatSuporte: false,
        mostraFormulario: false,
        siteIgo: 'https://astrazeneca.igorsvp.com.br', //'https://localhost:44351',
        dadosEvento: {
            cliqueAqui: false,
            nome_evento_sistema: "TRANSFORMATION TOUR CUIABA",
            nome_evento: "TRANSFORMATION TOUR CUIABA",
            tituloExibicao: 'TRANSFORMATION TOUR CUIABA',
            subtituloEvento: ``,
            dataEventoLand: `17 DE AGOSTO DE 2023`,
            comecou: `É AGORA`,
            id_evento: 18,
            data_inicio: "17/08/2023 08:00:00",
            data_termino: "17/08/2023 13:00:00",
            data_solicitacao: "18/07/2023",
            descricao: `Bem-vindo ao <span style='color:var(--cor-fundo);font-weight:800'> TR</span>ansformation <span style='color:var(--cor-fundo);font-weight:800'>TOUR 2023</span> da Thomson Reuters - Edição AGRO.
            <br><p class="descricao-personalizada">Nosso Road Show é um evento desenvolvido exclusivamente para executivos <i>C-level</i> de grandes corporações do Brasil. Já visitamos sete das principais cidades do país - junto com nossos parceiros, levando um evento de alto nível relacionado a temáticas das áreas Fiscal, Tributária e de Comércio Exterior.</p>
            <p class="descricao-personalizada02">No dia 17 de Agosto, nos encontraremos em Cuiabá para discutir sobre o Panorama Geral do agronegócio no Brasil e exterior, os principais desafios e oportunidades, além das melhores práticas e soluções para o segmento através do uso de tecnologia.<br>`,
            link: {
                exibicaoIGO: true,
                link: 'https://google.scom.br'
            },
            localEvento: {
                titulo: "Local",
                nome: 'Coco Bambu Cuiabá',
                rua: "Avenida Miguel Sútil, 9300, Luc 1001, Shopping Estação",
                cidade: "Cuiaba, Mato Grosso",
                site: "https://cocobambu.com/unidade/cuiaba/",
                mascara_site: "cocobambu.com/cuiaba",
                mapa: ``,
                mascara_mapa: "Clique para abrir o mapa",
                telefone: "(65) 3056-3052"
            },

        },
        industria: [
            "Aeronáutica",
            "Agrobusiness",
            "Alimentos e Bebidas",
            "Atacado",
            "Automotivo",
            "Bens de Consumo",
            "Comércio Exterior",
            "Construção Civil",
            "Consultoria",
            "Cooperativa",
            "Cosméticos",
            "Educação",
            "Eletroeletrônicos",
            "Energia",
            "Entretenimento",
            "Escritório de Advocacia",
            "Finanças",
            "Infraestrutura",
            "Maquinas e Equipamentos",
            "Midia e Marketing",
            "Mineração",
            "Oil & Gas",
            "Papel e Celulose",
            "Quimica/Farmaceutica",
            "Saúde",
            "Seguros",
            "Serviços Empresariais",
            "Setor Público",
            "Siderurgica/Metalurgica",
            "Tecnologia",
            "Telecomunicações",
            "Terceiro Setor",
            "Textil",
            "Transporte/Logistica/Distribuição",
            "Turismo/ Hotelaria",
            "Varejo",
            "Outro",

        ],
        moderadores: [

            // {
            //     color: "transparent",
            //     src: "https://cdn.igorsvp.com.br/landpages/speakers/LYNPARZA/Gilberto Castro.jpg",
            //     title: "Dr. Gilberto Castro",
            //     subtitle: `CRM-SP: 000084448`,
            //     palestrante: `Oncologista clínico do Hospital Sírio Libanês e ICESP. Presidente do GBOT (Grupo Brasileiro de Oncologia Torácica)`
            // },
        ],
        speakersinter: [

            // {
            //     color: "transparent",
            //     src: "https://cdn.igorsvp.com.br/landpages/speakers/LYNPARZA/Fred saad.png",
            //     title: "Fred Saad MD FRCS",
            //     subtitle: ``,
            //     palestrante: `Professor and Chairman of Urology. Director of GU Oncology. Raymond Garneau Chair in Prostate Cancer University of Montreal Hospital Center Montreal, Canada.
            //     `,
            // },

        ],
        palestrantes: [

            // {
            //     color: "transparent",
            //     src: "https://cdn.igorsvp.com.br/landpages/speakers/LYNPARZA/Petrucio Sarmento.jpg",
            //     title: "Dr. Petrúcio Sarmento",
            //     subtitle: `CRM-PB:000007105`,
            //     palestrante: `Cirurgião Torácico no Grutórax - Oncovida/ Hosp Português.`,
            // }
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/CarlosOttoni.jpg",
            title: "Carlos Ottoni",
            subtitle: ``,
            palestrante: `Tax - Trade & Customs Leader Partner at KPMG Brazil`,
          },
          // {
          //   color: "transparent",
          //   src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/",
          //   title: "Cleyton Henrique Barroso",
          //   subtitle: ``,
          //   palestrante: ``,
          // },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/edgarMadruga.jpg",
            title: "Edgar Madruga",
            subtitle: ``,
            palestrante: `Estrategista de Conteúdo na Treinar Prime | Coordenador do MBA em Contabilidade, Compliance e Direito Tributário e do MBA em Holdings na BSSP`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/FábioFigueiredo.jpg",
            title: "Fábio Figueiredo",
            subtitle: ``,
            palestrante: `Gerente Comercial NGR Global`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/FabioPaccaPerticarrari.png",
            title: "Fabio Pacca Perticarrari",
            subtitle: ``,
            palestrante: `Especialista de Negócio`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/FabíolaNesi.jpg",
            title: "Fabíola Nesi",
            subtitle: ``,
            palestrante: `Gerente de Comércio Exterior | Gerente de Importação e Exportação`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/FernandoPechula.jpg",
            title: "Fernando Pechula",
            subtitle: ``,
            palestrante: `Gerente de Produtos Digitais na NGR`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/santosNeto.jpg",
            title: "Frederico Favacho",
            subtitle: ``,
            palestrante: `ABAG (Associação Brasileira do Agronegócio)`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/GiovanaAraújo.jpg",
            title: "Giovana Araújo",
            subtitle: ``,
            palestrante: `Partner l Agribusiness Markets.<br>KPMG Brasil`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/JoaoRaphaelPeres.jpg",
            title: "João Raphael Peres",
            subtitle: ``,
            palestrante: `Gerente de Exportação e Logística da Tereos`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/KleberMartins.jpg",
            title: "Kleber Martins",
            subtitle: ``,
            palestrante: `BDM na Thomson Reuters Brasil`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/LuisFabianoMendes.jpg",
            title: "Luiz Fabiano Mendes",
            subtitle: ``,
            palestrante: `Head Centro-Oeste SPRO Group`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/renatoVieiraDeAvila.jpg",
            title: "Renato Vieira de Avila",
            subtitle: ``,
            palestrante: `CEO na Vieira de Avila Advocacia`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/RicardoJoria.jpg",
            title: "Ricardo Joria",
            subtitle: ``,
            palestrante: `Global Trade Operations & Compliance Manager | Cargill.`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/rodrigoDiaz.jpg",
            title: "Rodrigo Diaz",
            subtitle: ``,
            palestrante: `Senior Product Manager Thomson Reuters.`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/sidneiaAmorim.jpg",
            title: "Sidneia Amorim",
            subtitle: ``,
            palestrante: `Especialista das Soluções de Comércio Exterior - ONESOURCE Global Trade Thomson Reuters.`,
          },
          {
            color: "transparent",
            src: "https://cdn.igorsvp.com.br/landpages/speakers/TRANSFORMATIONTOURCUIABA/werintonGarciaMoreno.jpg",
            title: "Werinton Garcia Moreno",
            subtitle: ``,
            palestrante: `Diretor na Garcia & Moreno Consultoria Corporativa.`,
          },

        ],
        programacaoUnico: {
            horarios: [
                `19H30`,
                "20H00",
                "21H00",
            ],
            texto: [
                `Apresentação do Preceptoship Instituto Wilson Mello`,
                `Osteoartrite: Importância e Novos Conceitos`,
                `Opções Medicamentosas no Tratamento da OA`,
            ],
            textoCol3: [
                "Prof. Dr. Jorge Guedes",
                "Prof. Dr. Jorge Guedes",
                "Prof. Dr. Jorge Guedes",
            ],
        },
        programacao: [
            {
                // tab: "19 DE JULHO",
                // header: [
                //     "Horário",
                //     "Atividade",
                // ],

                // col1: [
                //     `17H00`,
                //     "17H30",
                //     "18H00 às 19h30",
                //     "20H00",

                // ],
                // col2: [
                //     `Welcome Drink`,
                //     `Abertura Institucional`,
                //     `Evento`,
                //     `Jantar`,
                // ],

            },
        ],
        fechaForm: false,
        emailAcessoLogin: null,
        dataEvento: {},
        menu: [{
            text: 'Sair',
            mostra: false,
            id: 'sair'
        },
        {
            text: 'Inscreva-se',
            mostra: false,
            id: 'cadastrese',
            cor: '#f5cb5d',
            class: 'btn_inscr'

        },
        {
            text: 'Sobre',

            mostra: true,
            id: 'padrao'
        },
        {
            text: 'Speakers',
            mostra: true,
            id: 'informacoes',
        },
        {
            text: 'Parceiros',
            mostra: true,
            id: 'patrocinadores'

        },
        {
            text: 'Programação',
            mostra: true,
            id: 'programacao',
        },
        {
            text: 'Local',
            mostra: true,
            id: 'local'

        },
        {
            text: 'Contato',
            mostra: true,
            id: 'contato'

        },
        {
            text: 'Outros Eventos',
            mostra: false,
            id: 'outroseventos'

        },


    ],
        id_evento: null,
        mostraItemMenu: true,
        uf: ["AC", "AL", "AM", "AP", "BA", "CE", "DF", "ES", "GO", "MA", "MG", "MS", "MT", "PA", "PB", "PE", "PI", "PR", "RJ", "RN", "RO", "RR", "RS", "SC", "SE", "SP", "TO"]
    },
    mutations: {
        toggleMenu(state, isVisible) {
            if (isVisible === undefined) {
                state.isMenuVisivel = !state.isMenuVisivel
            } else {
                state.isMenuVisivel = isVisible
            }
            console.log('toggleMenu = ' + state.isMenuVisivel)
        },
        SET_DRAWER(state, payload) {
            state.drawer = payload
        },
        SET_BAR_IMAGE(state, payload) {
            state.barImage = payload
        },
        mostraChat(state, payload) {
            state.chatSuporte = payload
        }
    },
    modules: {
        footer: {
            namespaced: true,
            state: () => ({
                logos: [
                    // { src: `AZ infomed.png`, enableLink: true, link: `https://www.azmed.com.br/`, width: `100%`, classe: "mx-auto", preenchimento: 'contain' },
                    // { src: `AZ SAC.png`, enableLink: true, link: `https://www.astrazeneca.com.br/contact/contato-sac.html`, width: `100%`, classe: "mx-auto", preenchimento: 'contain' },
                    { src: `LINKEDIN.png`, enableLink: true, link: `https://www.linkedin.com/company/thomson-reuters-brasil/`, width: `50%`, classe: "mx-auto", preenchimento: 'contain' },
                    { src: `FACE.png`, enableLink: true, link: `https://www.facebook.com/ThomsonReutersBrasil/`, width: `50%`, classe: "mx-auto", preenchimento: 'contain' },
                    { src: `twitter.png`, enableLink: true, link: `https://twitter.com/Brasil_TR`, width: `50%`, classe: "mx-auto", preenchimento: 'contain' },
                    { src: `INSTAGRAM.png`, enableLink: true, link: `https://www.instagram.com/thomsonreutersbr/`, width: `50%`, classe: "mx-auto", preenchimento: 'contain' },
                    // { src: `astrazeneca-logo.png`, enableLink: true, link: `https://www.astrazeneca.com.br/`, width: `100%`, classe: "mt-2", preenchimento: 'contain' },
                ],

                logos_remedios: [
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/Deloitte-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/Adejo-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/GSW-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/LKM-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/Atvi-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/Sevenfy-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/Alliance-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/BenFatto-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/BRL-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/J2R-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },
                //     {
                //         src:"https://CDN.IGORSVP.COM.BR/e-mails/cwt/Techedge-150px.jpg",
                //         height:"337",
                //         width:"600",
                //         style:"width:100%;height:auto;max-width:600px;"
                //     },                    
                ]

            })
        },
        agenda1: {
            namespaced: true,
            state: () => ({
              programacao: [{

              tab: "17/08/2023",

              header: [
                  { text: `<b>Horário</b>`, colspan: "2" },

                  { text: "" },
                  { text: `<b>Atividade</b>` },
              ],
              trs: [
                {col1:`08H00<br>09H00`, col2:` `, col3:`Welcome Coffee e Credenciamento`, col4:` `, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },
                {col1:`09H00<br>09H45`, col2:` `, col3:`Panorama do Agronegócio Brasileiro no Brasil e exterior`, col4:`<b>Edgar Madruga</b><br>Coordenador do MBA em Contabilidade, Compliance e Direito Tributário e do MBA em Holdings na BSSP<br><br><b>Frederico Favacho</b><br>ABAG - Associação Brasileira do Agronegócio<br><br><b>Giovana Araujo</b><br>Partner Agribusiness Markets na KPMG Brasil`, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },
                {col1:`09H45<br>10H15`, col2:` `, col3:`Planejamento tributário no Agronegócio`, col4:`<b>Werinton Garcia</b><br>Garcia e Moreno Consultoria Corporativa`, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },
                {col1:`10H15<br>10H45`, col2:` `, col3:`Holding Rural e Gestão de Sucessão`, col4:`<b>Renato Vieira de Avila</b><br>CEO na Vieira de Avila Advocacia`, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },
                {col1:`10H45<br>11H05`, col2:` `, col3:`Coffee Break`, col4:` `, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },
                {col1:`11H05<br>11H45`, col2:` `, col3:`Principais Desafios e Oportunidades para a Cadeia de Gestão do Agronegócio`, col4:`<b>Carlos Ottoni</b><br>Tax - Trade & Customs Leader Partner at KPMG Brazil<br><br><b>Fabíola Nesi</b><br>Gerente de Comércio Exterior | Gerente de Importação e Exportação<br><br><b>Sidneia Amorim</b><br>Especialista em Soluções de Comércio Exterior - ONESOURCE Global Trade Thomson Reuters`, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },
                {col1:`11H45<br>12H15`, col2:` `, col3:`Case Cargill`, col4:`<b>Ricardo Joria</b><br>Global Trade Operations & Compliance Manager na Cargill<br><br><b>Kleber Martins</b><br>BDM na Thomson Reuters`, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },
                {col1:`12H15<br>12H45`, col2:` `, col3:`Case Tereos`, col4:`<b>Fernando Pechula</b><br>Gerente de Produtos Digitais na NGR Global<br><br><b>Fábio Figueiredo</b><br>Gerente Comercial NGR Global<br><br><b>João Raphael Peres</b><br>Tereos`, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },
                {col1:`12H45<br>13H45`, col2:` `, col3:`Como a tecnologia potencializa as Exportações no Agronegócio: Soluções Integradas que beneficiam o Setor`, col4:`<b>Kleber Martins</b><br>BDM na Thomson Reuters<br><br><b>Fabio Pacca Perticarrari</b><br>Especialista de Negócio na GaTEC <br><br><b>Rodrigo Diaz</b><br>Senior Product Manager Thomson Reuters<br><br><b>Luiz Fabiano Mendes</b><br>Head Centro-Oeste SPRO Group`, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },
                {col1:`13H45<br>15H00`, col2:` `, col3:`Encerramento do evento e almoço`, col4:` `, color:`transparent`, fonteCol3:``, fonteCol4:`#edbe25`, },

              ],
          },



                ],
            }),
            getters: {
                // isAdmin () { ... } // -> getters['account/isAdmin']
            },
            actions: {
                // login () { ... } // -> dispatch('account/login')
            },
            mutations: {
                //  login () { ... } // -> commit('account/login')
            },
        },

        outrosEventos: {
            namespaced: true,
            state: () => ({
                programacao: [{

                        // tab: "15/06/2023",

                        header: [
                            // { text: "Horário", colspan: "2" },

                            // { text: "" },
                            // { text: "Atividade" },
                        ],
                        trs: [
                            // { col1: `22/06/2023`, col3: `CUIABA`, col4: ` `, color: `transparent`, fonteCol3: ``, fonteCol4: `#edbe25`, },

                        ],
                    },



                ],
            }),
            getters: {
                // isAdmin () { ... } // -> getters['account/isAdmin']
            },
            actions: {
                // login () { ... } // -> dispatch('account/login')
            },
            mutations: {
                //  login () { ... } // -> commit('account/login')
            },
        }

    },
})