<template>
  <v-row justify="center" class="mt-8 px-0">


    <v-card color="#fbfbfb9c" max-width="8rem" min-height="50rem" class="ml-2 pb-10 mt-5" tile>
      <v-card-title style="background-color: #fbfbfb2c;font-size: medium;" class="justify-center px-1">MAMA <br>
        <span style="font-size: 0.7rem;line-height: normal;text-align: center; font-weight: 600;">
          Moderador: <br>
          Dr. Carlos Barrios

        </span>
      </v-card-title>
      <table style="height: 100%;">
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center">Changing treatment
              paradigms based on HER2 expression level</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;"
              class="justify-center px-1 text-center">Palestrante: <br> Dr. Giuseppe Curigliano</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center">Rebiópsia na
              doença
              HER2+ metastática: sim ou não?</v-card-text>
          </td>
        </tr>
        <tr>
          <td>

            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center">Tratamento
              de
              paciente HER2+ com metástase cerebral</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;"
              class="justify-center px-1 pb-15 text-center">Palestrante: <br> Dr. Gilberto Amorim <br>
              Dr. Carlos dos Anjos
            </v-card-text>
          </td>
        </tr>
      </table>


    </v-card>

    <v-card color="#fbfbfb6a" class="ml-5 pb-10 mt-5" max-width="8rem" min-height="40rem" tile>
      <v-card-title style="background-color: #fbfbfb2c;font-size: medium;" class="justify-center px-0">OVÁRIO<br>
        <span style="font-size: 0.7rem;line-height: normal;text-align: center; font-weight: 600;">
          Moderadora: <br>
          Dra. Angélica Nogueira

        </span>
      </v-card-title>
      <table style="height: 100%;">
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center ">Ovarian Cancer
              treatment:
              Where we stand and where are we going?</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;"
              class="justify-center px-1 text-center">Palestrante: <br> Dr. Jonathan Lederman</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center ">Monoterapia x
              Combinação
              para pacientes de câncer de
              ovário HRD+ </v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center  ">Terapia de
              manutenção x conduta expectante</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.75rem;line-height: normal;"
              class="justify-center px-0 pb-15 text-center">
              Palestrante: <br> Dra. Michele Samora<br>
              Dra. Mariana Scaranti
            </v-card-text>
          </td>
        </tr>
      </table>




    </v-card>
    <v-card color="#fbfbfb9c" class="ml-5 pb-0 mt-5" max-width="8rem" min-height="40rem" tile>
      <v-card-title style="background-color: #fbfbfb2c; font-size: medium;" class="justify-center px-0">PRÓSTATA<br>
        <span style="font-size: 0.7rem;line-height: normal;text-align: center; font-weight: 600;">
          Moderador: <br>
          Dr. Fernando Maluf

        </span>
      </v-card-title>
      <table style="height: 100%;">
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center">Optimizing
              Management of mCPRC - What´s new</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;"
              class="justify-center px-1 text-center">Palestrante: <br>
              Dra. Eleni Efstathiou</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center pb-1">iParp x QT
              no mCRPC</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center pb-5">Devemos
              testar todos os
              pacientes com Câncer de próstata?</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.75rem;line-height: normal;"
              class="justify-center px-1 pb-16 pt-1 mb-2 text-center">
              Palestrante: <br> Dr. Daniel Herchenhorn<br>
              Dr. Denis Jardim
            </v-card-text>
          </td>
        </tr>
      </table>
    </v-card>

    <v-card color="#fbfbfb6a" class="ml-5 pb-0 mt-5" max-width="8rem" min-height="40rem" tile>
      <v-card-title style="background-color: #fbfbfb2c; font-size: medium;" class="justify-center px-1">GI<br>
        <span style="font-size: 0.7rem;line-height: normal;text-align: center; font-weight: 600;">
          Moderadora: <br>
          Dra. Marcela Crosara

        </span>
      </v-card-title>
      <table style="height: 100%;">

        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center pt-5">Atualizações
              em
              cânceres Hepatobiliares</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;"
              class="justify-center px-1 pt-15 text-center">Palestrante: <br>
              Dr. Diogo Bugano</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center ">Em quais pacientes
              com
              HCC evitaremos o uso de antiangiogânicos?
            </v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center ">Qual
              seria a
              terapia padrão para o tratamento HCC?</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.75rem;line-height: normal;"
              class="justify-center px-1 pb-15 mb-5 text-center">
              Palestrante: <br> Dr. Leonardo Fonseca<br>
              Dra. Ana Paula Victorino
            </v-card-text>
          </td>
        </tr>
      </table>
    </v-card>

    <v-card color="#fbfbfb9c" class="ml-5 pb-0 mt-5" max-width="8rem" min-height="40rem" tile>
      <v-card-title style="background-color: #fbfbfb2c; font-size: medium;line-height: 100%;"
        class="justify-center text-center px-1">PULMÃO <br>(visão clínica)<br>
        <span style="font-size: 0.7rem;line-height: normal;text-align: center; font-weight: 600;margin-top: 0.2rem;">
          Moderador: <br>
          Dr. Thiago Bueno

        </span>
      </v-card-title>
      <table style="height: 100%;">
        <tr>
          <td>
            <v-card-text style="font-weight: 500;margin-top: -1rem;" class="justify-center text-center px-1 pb-0 pt-1">
              Navegando nas
              opções de tratamentos para os pacientes CPNPC
              metastaticos</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text
              style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;margin-top: -2rem;"
              class="justify-center px-1  text-center">Palestrante: <br>
              Dr. Thiago Bueno</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center text-center px-1 ">
              Paciente com mutação driver</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text
              style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;margin-top: -2rem;"
              class="justify-center px-1  text-center">Palestrante: <br>
              Dra. Cinthia Bognar</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center text-center px-1">
              Paciente sem mutação driver</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.75rem;line-height: normal;"
              class="justify-center px-1 pb-15 mb-5 text-center">
              Palestrante: <br>
              Dr. Marcelo Corassa
            </v-card-text>
          </td>
        </tr>
      </table>
    </v-card>

    <v-card color="#fbfbfb6a" class="ml-5 pb-0 mt-5" max-width="8rem" min-height="40rem" tile>
      <v-card-title style="background-color: #fbfbfb2c; font-size: medium;line-height: 100%;"
        class="justify-center text-center px-1">PULMÃO <br>(visão cirúrgica)<br>
        <span style="font-size: 0.7rem;line-height: normal;text-align: center; font-weight: 600;margin-top: 0.2rem;">
          Moderadora: <br>
          Dra. Paula Ugalde

        </span>
      </v-card-title>
      <table style="height: 100%;">

        <tr>
          <td>
            <v-card-text style="font-weight: 500;margin-top: 0rem;" class="justify-center px-1 text-center pt-1">Como
              abordar o paciente
              IIIA: resseção cirúrgica vs. QRT + Imunoterapia</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text
              style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;margin-top: -2rem;"
              class="justify-center px-1  text-center">Palestrante: <br>
              Dra. Paula Ugalde</v-card-text>
          </td>
        </tr>

        <tr>
          <td>
            <v-card-text style="font-weight: 500; font-size: 0.85rem;" class="justify-center px-0 text-center">Paciente
              IIIA <br> o
              caminho cirúrgico</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text
              style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;margin-top: -1rem;"
              class="justify-center px-1  text-center">Palestrante: <br>
              Dr. Ricardo Terra</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500; font-size: 0.85rem; margin-top: -1rem;"
              class="justify-center px-0 text-center">Paciente
              IIIA <br> o
              caminho da quimiorradiação</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.75rem;line-height: normal;"
              class="justify-center px-1 pb-15 mb-7 text-center">
              Palestrante: <br>
              Dr. Wolfgang Aguiar
            </v-card-text>
          </td>
        </tr>
      </table>
    </v-card>

    <v-card color="#fbfbfb9c" class="ml-5 pb-0 mt-5" max-width="8rem" min-height="40rem" tile>
      <v-card-title style="background-color: #fbfbfb2c; font-size: medium;line-height: 100%;"
        class="justify-center text-center px-1">MEDICINA DE<br> PRECISÃO<br>
        <span style="font-size: 0.7rem;line-height: normal;text-align: center; font-weight: 600;margin-top: 0.2rem;">
          Moderadora: <br>
          Dra. Mª. Isabel Achatz

        </span>
      </v-card-title>
      <table style="height: 100%;">
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center text-center px-1 pt-10 ">Exoma clínico no
              estudo do câncer </v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text
              style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;margin-top: 3rem;"
              class="justify-center px-1  text-center">Palestrante: <br>
              Dr. João Bosco</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center text-center px-1 pt-3">Aconselhamento genético
              além do BRCA
            </v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text
              style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;margin-top: -1.5rem;"
              class="justify-center px-1  text-center">Palestrante: <br>
              Dra. Maria Isabel Achatz</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center text-center px-1 ">
              Oncogenética na prática: Estudo de caso
            </v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 600; font-style: italic;font-size: 0.75rem;line-height: normal;"
              class="justify-center px-1 pb-15 pt-10 mb-8 text-center">
              Palestrante: <br>
              Dra. Maria Isabel Achatz</v-card-text>
          </td>
        </tr>
      </table>
    </v-card>


    <v-card color="#fbfbfb6a" class="ml-5 pb-0 mt-5" max-width="8rem" min-height="40rem" tile>
      <v-card-title style="background-color: #fbfbfb2c; font-size: 0.95rem;line-height: 100%;"
        class="justify-center text-center px-0 py-8">ACESSO PÚBLICO<br>E PRIVADO
      </v-card-title>
      <table style="height: 100%;">

        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center pt-1">Explorando o conceito
              preço e valor: Desafio na era da Imuno-oncologia</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text
              style="font-weight: 600; font-style: italic;font-size: 0.75rem;line-height: normal;margin-top: 1.5rem;"
              class="justify-center px-1  text-center">Palestrante: <br>
              Dr. Stephen Stefani</v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center ">Oncológicos orais:
              conciliando necessidade de avanços com as barreiras de acesso
            </v-card-text>
          </td>
        </tr>
        <tr>
          <td>
            <v-card-text
              style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;margin-top: -0rem;"
              class="justify-center px-1  text-center">Palestrante: <br>
              Dr. Luciano Paladini</v-card-text>
          </td>
          </tr>

        <tr>
          <td>
            <v-card-text style="font-weight: 500;" class="justify-center px-1 text-center">Inovação no SUS:
              o que é possível no cenário atual </v-card-text>
          </td>
        </tr>
          <tr>
          <td>
            <v-card-text
              style="font-weight: 600; font-style: italic;font-size: 0.8rem;line-height: normal;margin-top: -1.5rem;"
              class="justify-center px-1  text-center  pb-15 mb-10">Palestrante: <br>
              Dr. Ronald Coelho</v-card-text>
          </td>
          </tr>
          
      </table>
    </v-card>

  </v-row>
</template>

<script>
export default {

}
</script>

<style>
</style>