<template>
  <v-container ref="form1">
    <v-row justify="center" class="mb-5" v-show="!mostraFormulario">
      <h1 v-show="mostraEscolheu" style="color: #4b4b4a">{{ msgInscrito }}</h1>
    </v-row>

    <v-row justify="center" class="mt-5">
      <label v-show="erroServidor.mostra" style="color: #f5cb5d; font-size: 1.3rem" v-html="erroServidor.texto"></label>
    </v-row>
    <v-row justify="center" v-show="loaderSkeleton">
      <v-col cols="11" class="campos" sm="4" md="3">
        <v-skeleton-loader type="article, article, article, article, actions"></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-form ref="formPax" v-model="valid" lazy-validation v-show="mostraFormulario">
      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-text-field :loading="loadingEmail" :disabled="loadingEmail" ref="nome" v-model="pax.nome" label="Nome:"
            placeholder="Nome completo" hint="Digite seu nome sem abreviação" outlined dense :rules="[rules.required]"
            required></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-text-field :loading="loadingEmail" :disabled="loadingEmail" ref="nome_cracha" v-model="pax.nome_cracha"
            label="Nome para crachá:" placeholder="Nome para crachá" hint="Digite seu nome para o crachá" outlined
            dense></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-text-field :loading="loadingEmail" :disabled="loadingEmail" ref="cpf" v-model="pax.cpf" label="CPF:"
            placeholder="CPF:" outlined dense hint="Somente números" :rules="[rules.required]" required maxlength="16"
            @keyup="numeroCPF"></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-text-field :loading="loadingEmail" :disabled="loadingEmail" ref="email" v-model="pax.email" label="E-mail:"
            placeholder="Email:" :hint="cadastrado" outlined dense :rules="[rules.required, rules.email]" required
            @blur="validaemail()">
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-text-field @keyup="maxCel1" maxlength="11" :loading="loadingEmail" :disabled="loadingEmail" ref="celular"
            v-model="pax.celular" label="Celular" placeholder="Celular" outlined dense hint="Somente números"
            :rules="[rules.required, rules.celular]" required></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-text-field :loading="loadingEmail" :disabled="loadingEmail" ref="empresa" v-model="pax.empresa"
            label="Empresa:" placeholder="Empresa:" outlined dense :rules="[rules.required]" required></v-text-field>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-text-field :loading="loadingEmail" :disabled="loadingEmail" ref="cargo" v-model="pax.especialidade"
            label="Cargo:" placeholder="Cargo:" outlined dense :rules="[rules.required]" required></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-select :loading="loadingEmail" :disabled="loadingEmail" :items="['SIM', 'NÃO']" ref="generico1"
            v-model="pax.generico1" label="Você é cliente Thomson Reuters?" placeholder="Você é cliente Thomson Reuters?"
            outlined dense :rules="[rules.required]" required></v-select>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-autocomplete :loading="loadingEmail" :disabled="loadingEmail" :items="$store.state.industria" ref="generico2"
            v-model="pax.generico2" label="A qual indústria pertence? (marcar uma)"
            placeholder="A qual indústria pertence? (marcar uma)" outlined dense :rules="[rules.required]"
            required></v-autocomplete>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="11" class="campos" sm="4" md="3">
          <v-text-field :loading="loadingEmail" :disabled="loadingEmail" ref="restricao_alimentar"
            v-model="pax.restricao_alimentar" label="Alguma restrição alimentar?"
            placeholder="Alguma restrição alimentar?" outlined dense :rules="[rules.required]" required></v-text-field>
        </v-col>
      </v-row>

      <!--Termos----checkbox--->
      <!--Termos----checkbox--->
      <v-row justify="center" class="mt-2">
        <v-col md="4" sm="10" cols="10" class="c-termos">
          <v-row>
            <!-- <small style="text-align: left; margin-left: 1rem">
            Os dados pessoais coletados em razão deste evento serão tratados em
            total conformidade com a Lei Federal nº 13.709/2018 (Lei Geral de
            Proteção de Dados).
            <a
              href="https://cdn.igorsvp.com.br/termos/igo/TERMOS-IGORSVP.pdf"
              target="_blank"
              >clique aqui para ver termo completo</a
            ></small
          > -->
            <p class="lgpd_text" style="text-align: justify">
              As informações colhidas no presente formulário serão utilizadas
              pelos organizadores do evento da Thomson Reuters e pelos nossos
              patrocinadores. O Usuário poderá, posteriormente, optar por deixar
              de receber as comunicações de e-mail seguindo as instruções da
              empresa. Para entender melhor como a Thomson Reuters coleta, usa e
              divulga os dados pessoais identificáveis de seus usuários, leia
              atentamente a nossa Declaração de Privacidade de dados
              disponibilizada em:<a href=" https://www.thomsonreuters.com/pt/declaracao-de-privacidade.html"
                target="_blank">
                thomsonreuters.com</a>

              Ao aceitar o presente formulário, o usuário declara que leu
              integralmente a Política de Privacidade e concorda com todas as
              ações que tomamos em relação aos seus dados. AUTORIZO o uso de
              minha imagem em todo e qualquer material entre imagens de vídeo,
              fotos e documentos, para ser utilizada pela Thomson Reuters em
              materiais de divulgação do evento <span style='color:var(--cor-fundo);font-weight:800'>
                TR</span>ansformation <span style='color:var(--cor-fundo);font-weight:800'>TOUR 2023</span>. A presente
              autorização é concedida a título gratuito, abrangendo o uso da
              imagem acima mencionada em todo território nacional, das seguintes
              formas: (I) home page; (II) mídia eletrônica (vídeo-tapes,
              televisão, cinema, entre outros) (III) redes sociais. Fica ainda
              autorizada, de livre e espontânea vontade, para os mesmos fins, a
              cessão de direitos da veiculação das imagens não recebendo para
              tanto qualquer tipo de remuneração. Por esta ser a expressão da
              minha vontade declaro que autorizo o uso acima descrito sem que
              nada haja a ser reclamado a título de direitos conexos à minha
              imagem ou a qualquer outro, e assino a presente autorização.
            </p>
            <v-checkbox ref="lgpd" v-model="ckLgpd" label="Aceito os termos LGPD" value="Convidado aceitou os termos LGPD"
              class="mt-0 lgpd_check"></v-checkbox>
          </v-row>

          <v-row class="mt-0" v-if="false">
            <v-checkbox color="#f5cb5d" dark class="mt-2 txt-chek" v-model="ckNoti"
              label="Aceito receber notificações referente ao evento"
              value="Aceitou receber notificações referente ao evento"></v-checkbox>
          </v-row>
        </v-col>
      </v-row>

      <!--fim-Termos----checkbox--->
      <v-row justify="center" class="mt-5">
        <label v-show="msgPreencha" style="color: #f5cb5d; font-size: 1.3rem">Preencha corretamente {{ erroCampo.texto
        }}!</label>
      </v-row>
      <v-row justify="center" class="mt-5">
        <v-btn v-if="mostraBtnInscreva" dark class="ma-2 botao-pulse" outlined :loading="loading4" :disabled="loading4"
          color="#f5cb5d" @click="submit">
          <!-- @click="maqueado" -->
          Inscrever
          <template v-slot:loader>
            <span class="custom-loader">
              <v-icon light>mdi-cached</v-icon>
            </span>
          </template>
        </v-btn>
      </v-row>
      <v-row justify="center" class="mt-5">
        <label v-show="msgErroGravar.mostra" style="color: #f5cb5d; font-size: 1.3rem">{{ msgErroGravar.texto }}</label>
      </v-row>
      <!-- </div> -->
      <input type="hidden" id="cpip" />
      <v-col cols="auto">
        <v-dialog v-model="dialogo" transition="dialog-top-transition" max-width="500" persistent>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar class="cabecalho-dialogo">{{
                d_msg.titulo
              }}</v-toolbar>
              <v-card-text>
                <div class="text pa-10" style="font-size: 1.2rem" v-html="d_msg.texto"></div>

                <div class="text pl-10 pr-10" style="font-size: 1.2rem" v-html="d_msg.texto2"></div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn text @click="dialog.value = false">Fechar</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-form>
    <v-dialog v-model="mostraObrigado" transition="dialog-top-transition" max-width="80vh">
      <v-card elevation="5" class="mt-10" min-width="90%" style="border-radius: 30px" color="var(--cor-primaria)">
        <v-card-title class="mb-5">
          <v-row class="text-h5 fundo-cor" justify="center" style="max-width: 90%; border-radius: 15px">
            <span>Inscrito(a)!</span>
          </v-row>
        </v-card-title>
        <v-card-text class="text-center px-10 text-h6 justify-center">
          Oi {{ pax.nome }} você já se inscreveu.
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn text @click="mostraObrigado = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>

const that = this;
import {
  baseEventoUrl,
  basePaxUrl,
  eventoID,
  baseCpfUrl,
  baseVerificaCidade,
  baseVerificaEmail,
  baseGravaPaxEvento,
  baseUpdatePaxEvento,
  baseVerificaPresenca,
  baseVerificaPresencaOptin,
} from "@/global";

import JQuery from "jquery";
import axios from "axios";
window.$ = JQuery;

export default {
  data: () => ({
    valid: true,
    loaderSkeleton: false,
    msgInscrito: "",
    mostraObrigado: false,
    mostraFormulario: false,
    token:
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InJhZmFlbEBpZ29yc3ZwLmNvbS5iciIsInJvbGUiOlsibGFuZHBhZ2UiLCJJR09fUlNWUCJdLCJlLW1haWwiOiJyYWZhZWxAaWdvcnN2cC5jb20uYnIiLCJub21lX3VzdWFyaW8iOiJSYWZhZWwgQW50b25hY2NpIiwibml2ZWwiOiJhZG1pbmlzdHJhZG9yIiwiY2F0ZWdvcmlhIjoiSUdPX1JTVlAiLCJpZF91c3VhcmlvIjoiNiIsImlkX2NsaWVudGUiOiIyMiIsImNsaWVudGUiOiJJR08iLCJuYmYiOjE2Nzg4MzI0MzUsImV4cCI6MTY5NjExMjQzNSwiaWF0IjoxNjc4ODMyNDM1fQ.3l1ZIfOevC5_lFrlPbbQ02oNZxGm107ODd7KlLWFN4Y",
    msgErroGravar: { texto: "", mostra: false },
    tipoDoc: "",
    registro: [
      { text: "CRM", value: "crm" },
      { text: "CRF", value: "crf" },
      // { text: "COREN", value: "coren" },
    ],

    banco: "cwt",
    mostraEscolheu: false,
    pax: {
      nome: "",
      email: "",
      celular: "",
      uf_crm: "",
      crm: "",
      cidade: "",
      id_evento: 0,
      banco: "",
      tipoDoc: "",
      qtd: "",
      especialidade: "",
      empresa: "",
    },
    erroCampo: { texto: "" },
    erroServidor: { texto: "", mostra: false },
    dialogo: false,
    d_msg: {
      texto: "",
      texto2: "",
      titulo: "",
    },

    mostraBtnInscreva: true,
    msgPreencha: false,
    evento: {},
    vagas: 0,
    // pax: [],
    status: {},
    idDopax: "",
    loader: [],
    loading4: false,
    loadingEmail: false,
    formHasErrors: false,
    ckNoti: ["Aceitou receber notificações referente ao evento"],
    ckLgpd: "",
    usuario: {},

    rules: {
      celular: (value) => {
        if (value != undefined) {
          if (value.length < 10) {
            return "Celular inválido";
          } else return true;
        } else return true;
      },
      crm: (value) => {
        if (value != undefined) {
          if (value.length < 7) {
            return "Digite os 7 numeros do CRM";
          } else return true;
        } else return true;
      },
      required: (value) => !!value || "Por favor preencha este campo.",
      requiredSelecao: (value) => !!value || "Por favor escolha uma opção.",
      counter: (value) => value.length <= 20 || "Max 20 caracteres",
      email: (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        return pattern.test(value) || "E-mail inválido.";
      },
    },
    cadastrado: "",
  }),

  computed: {
    id_evento() {
      return this.$store.state.dadosEvento.id_evento;
    },
    uflist() {
      return this.$store.state.uf;
    },
  },

  watch: {
    name() {
      this.errorMessages = "";
    },
  },

  methods: {
    maqueado() {
      maxCel1;
      this.d_msg.texto = "TRansformation TOUR 2023";
      this.d_msg.texto2 =
        "Obrigado por sua inscrição no evento <b>TRansformation TOUR 2023</b><br>em breve você receberá mais informações.";
      this.d_msg.titulo = "Inscrito com sucesso!";
      this.dialogo = true;
      this.loading4 = false;
      this.mostraEscolheu = false;
      this.resetForm();
      this.msgErroGravar.mostra = false;
      this.msgErroGravar.texto = "";
    },

    maxCel1(valor) {
      //console.log(valor, "valoe", this.pax);
      //  let v = valor.target.value;
      this.pax.celular = valor.target.value.replace(/\D/g, "");
      //valor.target.value = valor.target.value.replace(/\D/g, "");
    },
    loading(...loads) {
      const l = loads;

      l.forEach((f) => {
        this[f] = !this[f];
        setTimeout(() => (this[f] = false), 3000);
      });
      // this[l] = !this[l];

      this.loader = [];
    },
    validaemail() {
      const Valido = this.emailInvalido();

      if (Valido) {
        const email = this.pax.email;
        this.ExisteUsuarioEmail(email);
      }
      return Valido;
    },
    async ExisteUsuarioEmail(email) {
      // return
      this.loadingEmail = true;
      this.cadastrado = "";

      let formdata = {
        email: email,
        id_evento: this.id_evento,
        banco: this.banco,
      };
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      };

      let url = "";

      if (this.$route.query.optin !== undefined || this.$route.query.redesocial !== undefined) {
        url = `${baseVerificaPresencaOptin}`;
        //url = `${baseVerificaEmail}`;
      } else {
        this.loadingEmail = false;
        return;
        //  url = `${baseVerificaEmail}`;
        // url = `${baseVerificaPresenca}${q}`;
      }

      await axios
        .post(url, formdata, config)
        .then((res) => {
          let existe = res.data;
          console.log(existe);
          if (res.data.statusPresenca != null) {
            if (res.data.statusPresenca.includes("CONFIRMADO")) {
              console.log(res.data.statusPresenca, "confirmado");
              this.mostraBtnInscreva = false;
              this.cadastrado = "E-mail já cadastrado!";
              this.dialogo = true;
              this.d_msg.titulo = "Usuário já inscrito";
              this.d_msg.texto =
                "Este e-mail já está cadastrado no sistema!<br> Se você ainda não se inscreveu por favor utilize outro e-mail.";
            }
          } else {
            this.mostraBtnInscreva = true;
            this.cadastrado = "";
            // this.$refs['email'].validate(false);
          }
          this.loadingEmail = false;
        })
        .catch(() => {
          this.loadingEmail = false;
          this.cadastrado = "Erro inesperado tente novamente!";
        });
    },
    getIp() {
      $.getJSON(
        "https://api.ipify.org?format=jsonp&callback=?",
        function (json) {
          document.querySelector("#cpip").value = json.ip;
          return json.ip;
        }
      );
    },

    numeroCPF(valor) {
      this.pax.cpf = valor.target.value.replace(/\D/g, "");
    },
    numeroCRM(valor) {
      this.pax.crm = valor.target.value.replace(/\D/g, "");
    },
    emailInvalido() {
      const value = this.pax.email;
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!pattern.test(value)) {
        this.formHasErrors = true;

        return false;
      }
      return true;
    },
    addressCheck() {
      this.errorMessages =
        this.address && !this.name ? `Hey! I'm required` : "";

      return true;
    },
    resetForm() {
      this.errorMessages = [];
      this.formHasErrors = false;
      this.tipoDoc = "";

      Object.keys(this.pax).forEach((f) => {
        //console.log(this.$refs[f], f);
        this.$refs[f] ? this.$refs[f].reset() : "";
        this.pax[f] = "";
      });
    },
    async verificaPresenca() {
      this.msgErroGravar.mostra = false;
      this.msgErroGravar.texto = "";
      this.pax.banco = this.banco;
      this.pax.id_evento = this.id_evento;
      //this.pax.tipoDoc = this.tipoDoc;
      let q = this.$route.fullPath;
      let testeMailing = this.$route;

      let url = "";
      url = `${baseVerificaPresenca}${q}`;
      // this.mostraFormulario = true;
      if (this.$route.query.id == null || this.$route.query["w!"] == null) {
        return;
      }
      this.mostraFormulario = false;
      this.loaderSkeleton = true;
      console.log(testeMailing, "url----------------");
      // const url = `${baseGravaPaxEvento}`;
      //      this.loader1 = true;
      this.loading4 = true;
      this.msgPreencha = false;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      };
      await axios
        .post(url, this.pax, config)
        .then((res) => {
          console.log(res.data);

          Object.keys(res.data).forEach((el) => {
            if (res.data[el] == null) {
              res.data[el] = "";
            }
            // console.log(el,res.data[el])
          });
          // console.log( res.data," dados depois do object")
          let data = res.data;
          this.loading4 = false;
          this.loaderSkeleton = false;
          if (data.status_presenca == "CONFIRMADO") {
            this.pax.nome = data.nome;
            this.mostraObrigado = true;
            this.mostraFormulario = false;
            this.msgInscrito = `Oi ${data.nome} você já está inscrito(a)!`;
            this.mostraEscolheu = true;
          } else {
            this.pax = res.data;
            this.mostraObrigado = false;
            this.mostraFormulario = true;
            this.msgInscrito = "";
          }
        })
        .catch(() => {
          this.loaderSkeleton = false;
          this.loading4 = false;
          this.msgInscrito = "";
        });
    },
    submit() {
      this.msgPreencha = false;
      console.log(this.valid, "submit", this.$refs.formPax.validate());
      this.erroCampo.texto = "";
      //       for (var f in this.pax) {
      //         if(!this.pax[f]){
      //         this.formHasErrors = true;
      //         this.$refs[f].validate(true);
      //         this.erroCampo.texto = f
      //         this.msgPreencha = true;
      //         return;
      //       }
      //     console.log(f, this.pax[f]);
      // }
      // if (this.pax.cidade.length < 1) {
      //   this.formHasErrors = true;
      //   this.$refs["cidade"].validate(true);
      //   this.erroCampo.texto = "escolha um local";
      //   this.msgPreencha = true;
      //   return;
      // }
      if (this.pax.nome.length < 3) {
        this.formHasErrors = true;
        this.$refs["nome"].validate(true);
        this.erroCampo.texto = "o campo nome";
        this.msgPreencha = true;
        return;
      }
      if (this.pax.cpf.length < 11) {
        this.formHasErrors = true;
        this.$refs["cpf"].validate(true);
        this.erroCampo.texto = "o campo cpf";
        this.msgPreencha = true;
        return;
      }

      if (this.pax.empresa.length < 1) {
        this.formHasErrors = true;
        this.$refs["empresa"].validate(true);
        this.erroCampo.texto = "o campo empresa";
        this.msgPreencha = true;
        return;
      }
      if (this.pax.especialidade.length < 2) {
        this.formHasErrors = true;
        this.$refs["cargo"].validate(true);
        this.erroCampo.texto = "o campo cargo";
        this.msgPreencha = true;
        return;
      }

      if (!this.emailInvalido()) {
        this.$refs["email"].validate(true);
        this.erroCampo.texto = "o campo e-mail";
        this.msgPreencha = true;
        return;
      }

      if (this.pax.celular.length < 10) {
        this.formHasErrors = true;
        this.$refs["celular"].validate(true);
        this.erroCampo.texto = "o campo celular";
        this.msgPreencha = true;
        return;
      }

      this.pax.ip_usuario = document.querySelector("#cpip").value;

      if (!this.valid) {
        this.$refs.formPax.validate();
        this.erroCampo.texto = "os campos acima";
        this.msgPreencha = true;
        return;
      }
      this.formHasErrors = false;
      if (!this.ckLgpd) {
        this.d_msg.texto = "Para continuar você deve aceitar os termos LGPD";
        this.d_msg.texto2 = "";
        this.d_msg.titulo = "Aceite LGPD";
        this.dialogo = true;

        return;
      } else {
        this.d_msg.texto = "";
        this.d_msg.texto2 = "";
        this.d_msg.titulo = "";
        this.dialogo = false;
      }

      this.msgPreencha = true;
      if (!this.formHasErrors) {
        this.msgPreencha = false;
        let q = this.$route;
        console.log(q.query.optin, "url");
        if (q.query.optin !== undefined || q.query.redesocial !== undefined) {
          if (q.query.redesocial !== undefined) {
            this.pax.generico4 = "EM BREVE VOCÊ RECEBERÁ MAIS INFORMAÇÕES."
          } else {
            this.pax.generico4 = "INSCRITO COM SUCESSO!"
          }
          this.gravaCred("gravar");
        } else {
          this.gravaCred("update");
        }
        //this.ExisteVagas(this.pax.cidade, "gravar");
      }
    },
    async gravaCred(tipo) {
      this.msgErroGravar.mostra = false;
      this.msgErroGravar.texto = "";
      this.pax.banco = this.banco;
      this.pax.id_evento = this.id_evento;
      //this.pax.tipoDoc = this.tipoDoc;
      let url = "";
      let q = this.$route.fullPath;
      if (tipo == "update") {
        url = `${baseUpdatePaxEvento}${q}`;
      } else {
        if (tipo == "gravar") {
          url = `${baseGravaPaxEvento}${q}`;
        }
      }
      console.log(url, "url");
      // const url = `${baseGravaPaxEvento}`;
      //      this.loader1 = true;

      this.loading4 = true;
      this.msgPreencha = false;
      let config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.token}`,
        },
      };
      await axios
        .post(url, this.pax, config)
        .then((res) => {
          //  console.log("retorno do banco", res.data);
          ///this.editedItem = res.data;
          console.log(res.data, "gravacao");
          console.log("MEU TESTE - URL", url);

          if (url.endsWith('redesocial')) {
            this.d_msg.texto = "TRansformation TOUR 2023 - Edição AGRO";
            this.d_msg.texto2 = `Obrigado ${this.pax.nome}. Recebemos sua intenção em participar do TRansformation TOUR - Edição AGRO. <br>Em breve retornaremos com a confirmação de sua inscrição.`;

            this.dialogo = true;
            this.loading4 = false;
            // this.mostraEscolheu = false;
            this.resetForm();
            this.msgErroGravar.mostra = false;
            this.msgErroGravar.texto = "";
            this.mostraFormulario = false;
            this.mostraEscolheu = true;
          } else {
            this.d_msg.texto = "TRansformation TOUR 2023";
            this.d_msg.texto2 = `Obrigado ${this.pax.nome} por sua inscrição no evento <b>TRansformation TOUR 2023</b><br>em breve você receberá mais informações.`;

            this.d_msg.titulo = "Inscrito com sucesso!";
            this.dialogo = true;
            this.loading4 = false;
            // this.mostraEscolheu = false;
            this.resetForm();
            this.msgErroGravar.mostra = false;
            this.msgErroGravar.texto = "";
            this.mostraFormulario = false;
            this.msgInscrito = `Oi ${res.data.pax.nome} você foi inscrito(a) com sucesso!`;
            this.mostraEscolheu = true;
            // this.loading("loadingEmail", "loading4");
          }

        })
        .catch((err) => {
          this.msgErroGravar.mostra = true;
          this.msgErroGravar.texto = "Erro inesperado tente novamente!";
          this.loading4 = false;
          console.log(err.response, "erro do gravar");
        });
      // .finally(() => {
      //   console.log(this.editedItem, "finaly");
      // });
    },
  },
  mounted() {
    if (this.$route.query.id != null || this.$route.query["w!"] != null) {
      this.verificaPresenca();
    } else {
      if (this.$route.query.optin !== undefined || this.$route.query.redesocial !== undefined) {
        this.mostraFormulario = true;
      }
    }
    this.getIp();
  },
};
</script>


<style>
.cabecalho-dialogo {
  color: white !important;
  background-color: var(--cor-fundo);
}

.u-form-checkbox {
  font-size: 1rem;
}

.campos {
  padding-bottom: 0;
  padding-top: 0;
}

.theme--light.v-text-field--outlined>.v-input__control>.v-input__slot {
  background-color: white !important;
  font-size: 1.2rem;
}

.c-termos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

.lgpd_text {
  font-size: 1rem;
  font-weight: 450;
  text-indent: 2vw;
  margin-top: 3vh;
  width: 80vw !important;
}

.lgpd_check {
  margin: auto;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>