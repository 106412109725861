<template>
<div class="col-12 col-md-8">
      <h1 class="titulo-center titulo_ajuste_especial" :style="`color:${corIcone}`">{{titulo}}</h1>
      <v-row class="col-12 col-md-12 col-sm-10" id="divisoria">
      
          <div class="pontos-esq" :style="`color:${corIcone}`"></div>
          <div class="icone"> <v-icon large :color="corIcone">{{icon}}</v-icon></div>
          <div class="pontos-dir" :style="`color:${corIcone}`"></div>

   </v-row>
</div>
</template>

<script>
export default {
props:{
    icon:String,
    corIcone:String,
    titulo:String,
},
 data: () => ({

 }),
mounted(){

}
}
</script>

<style>
#divisoria{
    margin: 1rem auto;
    display: flex;
    flex-flow: nowrap;
    flex-direction: row;
}
.icone{

    padding: 0;
    margin-bottom: -16px;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.pontos-esq{
    width: 85%;
      border-bottom-style: dotted;
    border-width: 2px;
       margin-top: -1px;
       margin-left: -10px;
}
.pontos-dir{
    width: 85%;
      border-bottom-style: dotted;
    border-width: 2px;
       margin-top: -1px;
       margin-right: -10px;
}
.titulo_ajuste_especial {
    font-size: 2.1rem;
}
</style>