<template>
  <div class="conteudo">
    <router-view />
      
  </div>
</template>

<script>
export default {
name: 'Conteudo',

data: () => ({
hidden:false,
 }),
computed:{

},
  methods:{
    iniciaChat(){
    
    this.$store.commit('mostraChat', true)
    
  },
  
  },


}

</script>

<style>
.conteudo {
    grid-area: conteudo;
   
    padding: 0;
    margin: 0;
    color: var(--cor-fonte-clara);
}
</style>