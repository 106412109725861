<template>
  <v-footer app absolute class="footer mt-10 ajuste_especial_footer">
    <v-card flat tile width="100%" class="text-center footer mt-5">
      <v-container>

        <v-row
          justify="center"
          class="mx-auto col-12 col-sm-10 col-md-9 col-xl-6"
        >
          <v-col
            v-for="(item, i) in logos"
            :key="i"
            cols="3"
            sm="2"
            md="2"
            xl="2"
            class=""
          >
            <a :href="item.link" target="_blank" v-if="item.enableLink">
              <v-img
                :src="item.src"
                :class="item.classe"
                :width="item.width"
                :contain="item.preenchimento"
              >
              </v-img>
            </a>

            <v-img
              v-else
              :src="item.src"
              :class="item.classe"
              :width="item.width"
              :contain="item.preenchimento"
            >
            </v-img>
          </v-col>
       

        <!-- <v-row justify="center">
          <v-col cols="3" sm="2" md="2" xl="1" class="">
            <v-img src="AZ infomed.png" class="mr-0" width="" contain> </v-img>
          </v-col>
         <v-col cols="3" sm="2" md="2" xl="1" class="" >
            <v-img src="AZ SAC.png" width=""> </v-img>
          </v-col> -->
       
         <!-- <v-col cols="6" sm="4" md="4" xl="2" class="">
            <v-img src="LOGO-DS-ASTRAZENECA.png" width="" contain> </v-img>
          </v-col> -->
         <!-- <v-col cols="3" sm="2" md="2" xl="1" class="">
            <v-img src="AZ infomed.png" width=""> </v-img>
          </v-col> -->
        </v-row>
        <v-row
          justify="center"
          class="mx-auto col-12 col-sm-10 col-md-9 col-xl-6"
        >
      
          <!-- <v-col
            v-for="(item, i) in logos_remedios"
            :key="i"
            cols="3"
            sm="2"
            md="2"
            xl="2"
            class=""
          >
            <a class="bulas" :href="item.link" target="_blank" v-if="item.enableLink">
              <v-img
                :src="item.src"
                :class="item.classe"
                :width="item.width"
                :contain="item.preenchimento"
              >
             
              </v-img>
              Mini bula
            </a>

            <v-img
              v-else
              :src="item.src"
              :class="item.classe"
              :width="item.width"
              :contain="item.preenchimento"
            >
            </v-img>
          </v-col> -->

        </v-row>

      </v-container>
      <v-col class="mt-3">
        <v-row class="mb-0 pt-3" justify="center">
          <p class="pb-0 mb-0">
            <!-- BR-22733 - produzido em fevereiro/2023. Material destinado a
            profissionais de saúde de acordo com a política de ativos digitais. Março 2023. -->
          </p>
        </v-row>
        <v-row justify="center" class="mt-0 pt-0">
          <p>
            Caso não deseje receber nossos materiais/e-mails, informe seu
            consultor.
          </p>
        </v-row>
        <v-row class="mt-0" justify="end">
          <p class="igo-footer">
            <a
              class="link-decoration"
              style="color: var(--cor-fonte-claro)"
              href="https://igorsvp.com.br"
              target="blank"
            >
              Powered by IGO Sistemas</a
            >
          </p>
        </v-row>
      </v-col>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "Footer",
  data: () => ({
    items: ["default", "absolute", "fixed"],
    padless: false,
    variant: "default",
  }),
  computed: {
    logos() {
      return this.$store.state.footer.logos;
  
    },
    logos_remedios() {
    
      return this.$store.state.footer.logos_remedios;
    },
  },
};
</script>
<style >
.bulas{
  text-decoration: none; 
  font-weight: 600;
  }
  @media screen and (max-width: 900px) {
  
    .bulas{

  font-weight: 500;
  font-size: 0.8rem;
  }
}

.footer {
  background-color: var(--footer-cor) !important;
  color: #000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.footer p {
  padding-top: 1rem;
  line-height: 1;
}
.igo-footer {
  font-size: 0.7rem;
  float: right;
  padding-top: 0;
}
</style>