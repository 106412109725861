<template>
    <div class="pale col-md-12  col-12" id="palestrante">
  
      <v-container class="mt-10">

        <h1 class="titulo-center" v-if="false" v-show="verPalInter">Palestrante internacional:</h1>
        <v-row dense class="col-md-12 col-xl-12 col-sm-12 mx-auto " v-show="verPalInter" justify="center">
          <v-col cols="10" sm="4" md="3" lg="3" xl="3" v-for="(item, i) in speakersinter" :key="i"
            class=" mt-0 mx-1  linha-palestrante-miniview">
            <v-col cols="12" class="mx-auto px-2 d-flex justify-start justify-md-start justify-sm-center ">
                  <v-card :color="item.color" dark class="card-palestrante mb-0 pa-0 col-12">
                <div class="clearfix texto w-100 pa-0" :id="'pw' + i" @mouseenter="mostraTexto($event, 'in')"
                  @mouseleave="mostraTexto($event, 'out')">
  
                  <v-avatar class="col-md-3   mx-auto" style="padding:4px" color="orange" size="10.5rem" >
                    <v-img :src="item.src" class="" position="top" max-height="10rem" size="10rem" :title="item.title"></v-img>
                  </v-avatar>
              
                  
                  <v-card-title :id="'titulo' + i" class="titulo py-1 pa-0 text-center ma-auto cor-texto-titulo w-100" v-text="item.title">
                  </v-card-title>
                  <v-card-subtitle class="subtitulo text-center mx-auto my-auto cor-texto-subtitulo pb-1 pa-0" v-html="item.subtitle">
                  </v-card-subtitle>
                  <v-card-subtitle v-show="item.src != ''" class=" mx-auto text-center especialidade pa-0 pt-0">
                    <v-btn class="px-0 ml-0" plain @click="miniView(item)">
                      mini CV
                    </v-btn>
                  </v-card-subtitle>
              
  
                </div>
              </v-card>
            </v-col>
  
          </v-col>
        </v-row>
          <h1 class="titulo-center"  v-show="verModerador">Moderador:</h1>

        <v-row dense class="col-md-12 col-xl-12 col-sm-12 mx-auto "   v-show="verModerador" justify="center">
          <v-col cols="10" sm="4" md="4" lg="4" xl="4" v-for="(item, i) in moderadores" :key="i"
            class=" mt-0 mx-1  linha-palestrante-miniview">
            <v-col cols="12" class="mx-auto px-2 d-flex justify-start justify-md-start justify-sm-center ">
              <v-card :color="item.color" dark class="card-palestrante mb-0 col-12">
                <div class="clearfix texto" :id="'pw' + i" @mouseenter="mostraTexto($event, 'in')"
                  @mouseleave="mostraTexto($event, 'out')">
  
                  <v-avatar class="col-md-3   mx-auto" style="padding:4px" color="orange" size="10.5rem" >
                    <v-img :src="item.src" class="" position="top" max-height="10rem" size="10rem" :title="item.title"></v-img>
                  </v-avatar>
  
              
                  
                  <v-card-title :id="'titulo' + i" class="titulo py-1 text-center mx-auto cor-texto-titulo w-100" v-text="item.title">
                  </v-card-title>
                  <v-card-subtitle class="subtitulo text-center mx-auto my-auto cor-texto-subtitulo pb-1" v-html="item.subtitle">
                  </v-card-subtitle>
                  <v-card-subtitle v-show="item.src != ''" class=" mx-auto especialidade pt-0">
                    <v-btn class="px-0 ml-0" plain @click="miniView(item)">
                      mini CV
                    </v-btn>
                  </v-card-subtitle>
              
  
                </div>
              </v-card>
            </v-col>
  
          </v-col>
        </v-row>
        <h1 class="titulo-center h1_palesConfirmados" v-show="verpaleNacional">Palestrantes Confirmados:</h1>
        <v-row dense class="col-md-12 col-xl-12 col-sm-12 mx-auto "  v-show="verpaleNacional" justify="center">
          <!-- <h1 class="titulo-center">Em breve</h1> -->
          <v-col v-show="true" cols="10" sm="4" md="3" lg="3" xl="3" v-for="(item, i) in palestrantes" :key="i"
            class=" mt-0 mx-1  linha-palestrante-miniview">
            <v-col cols="12" class="mx-auto px-2 d-flex justify-start justify-md-start justify-sm-center ">
              <v-card :color="item.color" dark class="card-palestrante mb-0 pa-0 col-12">
                <div class="clearfix texto w-100 pa-0" :id="'pw' + i" @mouseenter="mostraTexto($event, 'in')"
                  @mouseleave="mostraTexto($event, 'out')">

                  <v-avatar class="col-md-3   mx-auto" style="padding:4px" color="orange" size="10.5rem" >
                    <v-img :src="item.src" class="" position="top" max-height="10rem" size="10rem" :title="item.title"></v-img>
                  </v-avatar>

                  <v-card-title :id="'titulo' + i" class="titulo py-1 pa-0 text-center ma-auto cor-texto-titulo w-100" v-text="item.title">
                  </v-card-title>

                  <div class="descricaoSpeakers" v-html="item.palestrante"></div>

                  <v-card-subtitle v-if="false" class="subtitulo text-center mx-auto my-auto cor-texto-subtitulo pb-1 pa-0" v-html="item.subtitle">
                  </v-card-subtitle>
                  <v-card-subtitle v-show="item.src != ''" class=" mx-auto text-center especialidade pa-0 pt-0">
                    <v-btn v-if="false" color="black" class="px-0 ml-0" plain @click="miniView(item)">
                      mini CV
                    </v-btn>
                  </v-card-subtitle>
  
                </div>
              </v-card>
            </v-col>
  
          </v-col>
        </v-row>
  

        <v-row justify="center">
          <v-dialog v-model="dialog" max-width="420">
  
            <v-card>
  
              <v-card-title class="text-h5">
                {{ sel.title }}
              </v-card-title>
              <v-card-text v-html="sel.palestrante"></v-card-text>
  
              <v-card-actions>
                <v-spacer></v-spacer>
  
                <v-btn color="green darken-1" text @click="dialog = false">
                  Fechar
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  // import Formulario from "./FormularioDialog.vue";
  
  // import axios from "axios";
  // import $ from "jquery";
  
  // import { baseEventoUrl, eventoID } from "@/global";
  // import Divisoria from "./DivisoriaPontilhada.vue";
  export default {
    name: "PaleModeradores",
  
    components: {
      // Divisoria
    },
    data: () => ({
      verModerador:false,
      verpaleNacional:true,
      verPalInter:true,
      evento: [],
      palestrantes: [],
      mostraTitulo: false,
      dialog: false,
      sel: {},
      palestrantes_impar: [],
      moderadores:[],
      speakersinter:[]
  
    }),
    computed: {},
    watch: {},
    methods: {
      miniView(a) {
        this.sel = a
        this.dialog = true
        console.log(a)
      },
      mostraTexto(a, b) {
        let id = a.target.id;
        let w = $(window).width();
        //console.log(navigator.userAgent.match("Windows"));
        id = id.replace("p", "");
  
        let t = "#titulo" + id;
        // let v = document.getElementById('titulo' +id)
        let s = "#stitulo" + id;
        // v.style.visibility = 'hidden'
        // s.style.visibility ='hidden'
        if (b == "out") {
          $(s).fadeOut();
          //$(s).fadeOut();
        }
        if (b == "in") {
          $(s).fadeIn();
          // $(s).fadeIn();
        }
        // console.log(id, b);
      },
    },
    mounted() {
      this.$store.state.titulos = "Home";
      this.$store.state.icones = "mdi-home";
  
      let tipo = this.$route.query;
      let evento = "";
      evento = tipo.evento || "";
  
      if (evento == "dois") {
        this.palestrantes = this.palestrantes.concat(
          this.$store.state.palestrantes2,
          this.$store.state.palestrantes
        );
      } else {
        this.palestrantes = this.$store.state.palestrantes;
  
      }
      this.moderadores = this.$store.state.moderadores;
      this.speakersinter = this.$store.state.speakersinter;
    },
    destroyed() {
      this.$store.state.titulos = "";
      this.$store.state.icones = "";
    },
  };
  </script>
  <style scoped>
  /* .titulo-center {
    text-align: center;
    margin-bottom: 1rem;
    /* color: #dc9e09; */
  /* }  */

.h1_palesConfirmados{
  margin-bottom: 2rem !important;
}

  .descricaoSpeakers {
  font-size: .85rem !important;
  color: rgb(0, 0, 0, 60%);
  text-align: center;
}

  .titulo-center-parte-claro {
    text-align: center;
    margin-bottom: 1rem;
    /* color: #dc9e09; */
    color: var(--cor-fonte-clara);
  }
  
  .clearfix {
    display: flex;
    flex-direction: column;
  }
  
  .texto {
    width: 100%;
  
  }
  
  .texto .v-card__title {
    font-size: 1.1rem !important;
    /* color: var(--cor-fonte-clara); */
    color: black;
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0;
    font-weight: 400 !important;
    letter-spacing: 0.05rem !important;
    /* text-transform: uppercase; */
    white-space: nowrap;
  }
  
  .texto .v-card__subtitle {
    font-size: 0.85rem !important;
    color: var(--cor-fonte-clara) !important;
    font-weight: 800;
    letter-spacing: 0.01rem !important;
    padding-left: 0 !important;
    font-weight: 300;
    line-height: 120%;
    white-space: nowrap;
  }
  
  .texto .v-card__text {
    font-size: 1rem !important;
    color: var(--cor-fonte-clara) !important;
  }
  
  
  .card-palestrante.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: 0 0 0;
    border-radius: 0;
  }
  
  .linha-palestrante-miniview {
    /* border-bottom: solid white 1px; */
  
  
    margin: 0.1rem;
    /* height:20rem; */
  }
  
  .img-border {
    border-radius: 1rem !important;
  }
  
  @media screen and (max-width: 1290px) {
  
    .texto .v-card__title {
      font-size: 0.87rem !important;
      letter-spacing: 0.01rem !important;
      padding-left: 0 !important;
      font-weight: 300;
      line-height: 110%;
  
    }
  
    .texto .v-card__subtitle {
      font-size: 0.7rem !important;
  
  
    }
  
    .texto .v-card__text {
      font-size: 1rem !important;
  
    }
      .clearfix {
      align-items: center;
    }
  
  }
  
  
  
  @media screen and (max-width: 590px) {
  
    .clearfix {
      align-items: center;
    }
  }
  </style>