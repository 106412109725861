<template>
  <div class="loca" id="local">
    <h1 class="titulo-center">{{ local.titulo }}</h1>

    <v-container class="col-12 col-md-6 mt-10">
      <div class="fonte-subtitulo">
        <p style="text-transform: uppercase">
          <b>{{ local.nome }}</b>
        </p>
        <p class="mt-5">
          {{ local.rua }}
        </p>
        <p>{{ local.cidade }}</p>
        <p class="mt-5">
          <a class="link-sem" :href="local.site" target="blank">{{
            local.mascara_site
          }}</a>
        </p>
        <p v-html="local.telefone"></p>
        <v-col md="8" cols="10" class="mx-auto" >
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3843.0293189623944!2d-56.122776176309934!3d-15.590082317954476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x939db3dfa54e866b%3A0x4272b0f064321974!2sCoco%20Bambu%20Cuiab%C3%A1%3A%20Restaurante%2C%20Peixe%2C%20Camar%C3%A3o%2C%20Carnes%2C%20Lagosta%2C%20Salada%2C%20MT!5e0!3m2!1spt-BR!2sbr!4v1689779080291!5m2!1spt-BR!2sbr"
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </v-col>
      </div>
    </v-container>
  </div>
</template>

<script>
// import Formulario from "./FormularioDialog.vue";

// import axios from "axios";

// import { baseEventoUrl, eventoID } from "@/global";
// import Divisoria from "./DivisoriaPontilhada.vue";
export default {
  name: "LocaEvento",
  props: {
    evento: null,
  },
  components: {
    // Divisoria
  },
  data: () => ({
    local: "",
    local2: "",
    dois: "",
  }),
  computed: {},
  watch: {},
  methods: {},
  mounted() {
    this.local = this.$store.state.dadosEvento.localEvento;
    // this.local2 = this.$store.state.dadosEvento.localEvento2;

    let tipo = this.$route.query;
    let evento = "";
    evento = tipo.evento || "";

    if (evento == "dois") {
      this.dois = "ok";
    }
  },
  destroyed() {},
};
</script>
<style>
.fonte-subtitulo p {
  margin-bottom: 0.3rem;
}
.fonte-subtitulo {
  text-align: center;
}
.link-sem {
  text-decoration: none;
  color: var(--cor-fonte-clara) !important;
}
.loca {
  margin: auto;
}
</style>