<template>
  <v-container class="loca mt-5" id="contato">
      <v-row justify="center">
   <v-col cols="12" sm="6">
    <h1 class="titulo-center">Contato</h1>
    
   
      <div class="fonte-subtitulo mt-2">
        <p style="text-transform: uppercase">
          <b>RSVP - CWT</b>
        </p>
        <p class="mt-5">
         Telefone: (11) 2189-0740
        </p>
       
        <p class="mt-5">
          <a class="link-sem" href="mailto:transformationtour.cuiaba@igorsvp.com.br" target="blank">transformationtour.cuiaba@igorsvp.com.br</a>
        </p>
      </div>
   </v-col>
      </v-row>
  </v-container>
</template>

<script>
// import Formulario from "./FormularioDialog.vue";

// import axios from "axios";

// import { baseEventoUrl, eventoID } from "@/global";
// import Divisoria from "./DivisoriaPontilhada.vue";
export default {
  name: "LocaEvento",
  props: {
    evento: null,
  },
  components: {
    // Divisoria
  },
  data: () => ({
    local: "",
    local2: "",
    dois: "",
  }),
  computed: {},
  watch: {},
  methods: {},
  mounted() {
    this.local = this.$store.state.dadosEvento.localEvento;
    // this.local2 = this.$store.state.dadosEvento.localEvento2;

let tipo = this.$route.query;
    let evento = "";
    evento = tipo.evento || "";

    if (evento == "dois") {
      this.dois = "ok";
    }
  },
  destroyed() {},
};
</script>
<style>
.fonte-subtitulo p {
  margin-bottom: 0.3rem;
}
.fonte-subtitulo {
  text-align: center;
}
.link-sem {
  text-decoration: none;
  color: var(--cor-fonte-clara) !important;
}
.loca {
  margin: auto;
}
</style>