<template>
  <v-app>
    <!-- <Menu /> -->

    <Cabecalho />

    <!-- Sizes your content based upon application components -->
    <v-main
      class="main-cont"
      style="padding-top: 0; padding-bottom: 112px"
    >
      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <Conteudo />
        <!-- If using vue-router --
      <router-view></router-view>
      -->
      </v-container>
    </v-main>

    <Footer />
  </v-app>
</template>

<script>
// @ is an alias to /src

import Cabecalho from "./components/Cabecalho.vue";
// import Menu from "./components/Menu.vue";
import Conteudo from "./components/Conteudo.vue";
import Footer from "./components/Footer.vue";
import "@/assets/css/loading.css";
// import Chat from './components/Chat/Chat.vue';

export default {
  name: "Home",

  components: {
    Cabecalho,
    // Menu,
    Conteudo,
    Footer,
    // Chat,
  },
};
</script>
<style lang="scss">

body {
  width: 100%;
  height: 100vh;
}
main {
   background-image: var(--cor-degrade-1);
  //background-color: var(--cor-fundo);
  overflow: auto;
  // padding-top: 63px;
  // background-color: white;
  // background-color: #f5f7fa;
}
:root {
    /* --cor-fundo: #060566; */
    --cor-astra-escuro: #600c32;
    --cor-astra-amarelo: #edbe25;
    }
.fundo-cor {
  // background-color: var( --cor-fundo);
  color: white;
  width: 95%;
  border-radius: 6px;
  /* border: solid 1px; */
  padding: 1.5rem;
  margin-top: -3rem;
  margin-left: auto;
  margin-right: auto;
}
.container {
  padding: 0 !important;
}
.borda-fina {
  border-width: 1px;
  border-radius: 4px;
  font-size: 1.1rem;
}
.dicas {
  width: 80%;
  border: none;
  color: darkcyan;
  font-size: 1rem;
  margin: 1rem;
}
.v-label {
  font-size: 1.2rem;
}
.borda-arredondado {
  margin: 1rem 1rem 1rem 1rem;
  border: solid 2px;
  border-color: var(--v-checado-base);
  border-radius: 52px;
  padding: 0.7rem 0.7rem;
  height: 100%;
}
#nav {
  padding: 30px;

  /*
  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
  */
}

body{
  width: 100%;
}
.btn-inscrevase {
  padding: 1rem 5rem !important;
}

.btn-inscrevase .v-btn__content {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--botoes-cor-text-escuro);
}
.linha-inscrevase {
  margin: 2rem auto 5rem auto;
}


.home {
  font-size: 18px;
  letter-spacing: 0.08rem !important;
}
.fonte-descricao {
  font-size: 1.3rem;
  font-weight: 600;
}
.divisao {
  width: 80%;
  margin: auto;
}
.logar {
  margin-top: 0rem;
  margin-bottom: 5rem;
}
.naoinscrito{
  margin-bottom: 1rem;
}

.texto-evento {
  color: white !important;
}
.texto-evento h1 {
  font-size: 3rem;
}
 

</style>

